const env = 'production';

const AppConfig = {
    canonical: 'https://biutify.it',
    ui: {
        defaultPicProfile: '/img/default-user.jpg',
        breakpoints: {
            default: 4,
            1100: 3,
            700: 2,
            500: 1
        }
    },
    referral: {
        href: 'https://biutify.it/wp',
        title: 'BIUTIFY',
        label: 'BIUTIFY'
    },
    services: {
        facebook: {
            appid: '841998359271267',
            pageid: '1478436915519881',
        },
        google: {
            maps_api_key: 'AIzaSyDWLT3tLQS67AvifUQxEj3TEEETgHqhxGs'
        }
    },
    login_app: env === 'production' ? 'https://account.biutify.it' : 'http://localhost:3002',
	cookies: {
		login: { name: 'login', props: '' }
	}
}

export default AppConfig;