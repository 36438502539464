export const dropdownCities = [
  { label: 'In Italia', value: 'ovunque' },
  { label: 'Roma', value: 'roma' },
  { label: 'Agrigento', value: 'agrigento' },
  { label: 'Alessandria', value: 'alessandria' },
  { label: 'Ancona', value: 'ancona' },
  { label: "Valle d'Aosta", value: 'valle-daosta' },
  { label: 'Ascoli Piceno', value: 'ascoli-piceno' },
  { label: "L'Aquila", value: 'laquila' },
  { label: 'Arezzo', value: 'arezzo' },
  { label: 'Asti', value: 'asti' },
  { label: 'Avellino', value: 'avellino' },
  { label: 'Bari', value: 'bari' },
  { label: 'Bergamo', value: 'bergamo' },
  { label: 'Biella', value: 'biella' },
  { label: 'Belluno', value: 'belluno' },
  { label: 'Benevento', value: 'benevento' },
  { label: 'Bologna', value: 'bologna' },
  { label: 'Brindisi', value: 'brindisi' },
  { label: 'Brescia', value: 'brescia' },
  { label: 'Bolzano/Bozen', value: 'bolzano-bozen' },
  { label: 'Cagliari', value: 'cagliari' },
  { label: 'Campobasso', value: 'campobasso' },
  { label: 'Caserta', value: 'caserta' },
  { label: 'Chieti', value: 'chieti' },
  { label: 'Caltanissetta', value: 'caltanissetta' },
  { label: 'Cuneo', value: 'cuneo' },
  { label: 'Como', value: 'como' },
  { label: 'Cremona', value: 'cremona' },
  { label: 'Cosenza', value: 'cosenza' },
  { label: 'Catania', value: 'catania' },
  { label: 'Catanzaro', value: 'catanzaro' },
  { label: 'Enna', value: 'enna' },
  { label: 'Forlì-Cesena', value: 'forlì-cesena' },
  { label: 'Ferrara', value: 'ferrara' },
  { label: 'Foggia', value: 'foggia' },
  { label: 'Firenze', value: 'firenze' },
  { label: 'Fermo', value: 'fermo' },
  { label: 'Frosinone', value: 'frosinone' },
  { label: 'Genova', value: 'genova' },
  { label: 'Gorizia', value: 'gorizia' },
  { label: 'Grosseto', value: 'grosseto' },
  { label: 'Imperia', value: 'imperia' },
  { label: 'Isernia', value: 'isernia' },
  { label: 'Crotone', value: 'crotone' },
  { label: 'Lecco', value: 'lecco' },
  { label: 'Lecce', value: 'lecce' },
  { label: 'Livorno', value: 'livorno' },
  { label: 'Lodi', value: 'lodi' },
  { label: 'Latina', value: 'latina' },
  { label: 'Lucca', value: 'lucca' },
  { label: 'Monza e della Brianza', value: 'monza-e-della-brianza' },
  { label: 'Macerata', value: 'macerata' },
  { label: 'Messina', value: 'messina' },
  { label: 'Milano', value: 'milano' },
  { label: 'Mantova', value: 'mantova' },
  { label: 'Modena', value: 'modena' },
  { label: 'Massa-Carrara', value: 'massa-carrara' },
  { label: 'Matera', value: 'matera' },
  { label: 'Napoli', value: 'napoli' },
  { label: 'Novara', value: 'novara' },
  { label: 'Nuoro', value: 'nuoro' },
  { label: 'Oristano', value: 'oristano' },
  { label: 'Palermo', value: 'palermo' },
  { label: 'Piacenza', value: 'piacenza' },
  { label: 'Padova', value: 'padova' },
  { label: 'Pescara', value: 'pescara' },
  { label: 'Perugia', value: 'perugia' },
  { label: 'Pisa', value: 'pisa' },
  { label: 'Pordenone', value: 'pordenone' },
  { label: 'Prato', value: 'prato' },
  { label: 'Parma', value: 'parma' },
  { label: 'Pistoia', value: 'pistoia' },
  { label: 'Pesaro e Urbino', value: 'pesaro-e-urbino' },
  { label: 'Pavia', value: 'pavia' },
  { label: 'Potenza', value: 'potenza' },
  { label: 'Ravenna', value: 'ravenna' },
  { label: 'Reggio Calabria', value: 'reggio-calabria' },
  { label: "Reggio nell'Emilia", value: 'reggio-nellemilia' },
  { label: 'Ragusa', value: 'ragusa' },
  { label: 'Rieti', value: 'rieti' },
  { label: 'Rimini', value: 'rimini' },
  { label: 'Rovigo', value: 'rovigo' },
  { label: 'Salerno', value: 'salerno' },
  { label: 'Siena', value: 'siena' },
  { label: 'Sondrio', value: 'sondrio' },
  { label: 'La Spezia', value: 'la-spezia' },
  { label: 'Siracusa', value: 'siracusa' },
  { label: 'Sassari', value: 'sassari' },
  { label: 'Sud Sardegna', value: 'sud-sardegna' },
  { label: 'Savona', value: 'savona' },
  { label: 'Taranto', value: 'taranto' },
  { label: 'Teramo', value: 'teramo' },
  { label: 'Trento', value: 'trento' },
  { label: 'Torino', value: 'torino' },
  { label: 'Trapani', value: 'trapani' },
  { label: 'Terni', value: 'terni' },
  { label: 'Trieste', value: 'trieste' },
  { label: 'Treviso', value: 'treviso' },
  { label: 'Udine', value: 'udine' },
  { label: 'Varese', value: 'varese' },
  { label: 'Verbano-Cusio-Ossola', value: 'verbano-cusio-ossola' },
  { label: 'Vercelli', value: 'vercelli' },
  { label: 'Venezia', value: 'venezia' },
  { label: 'Vicenza', value: 'vicenza' },
  { label: 'Verona', value: 'verona' },
  { label: 'Viterbo', value: 'viterbo' },
  { label: 'Vibo Valentia', value: 'vibo-valentia' },
]
