import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import Api from "../api";
import {
  newRegistrationRequest,
  newRegistrationRequestSuccess,
  newRegistrationSetupRequest,
  newRegistrationSetupRequestSuccess,
} from "../slices/auth";

function* addNewRegistrationSaga({ payload: { formData, _next } }) {
  console.log("Form-data:", formData);
  const res = yield call(Api.auth.registration, formData);
  // const res = ''
  console.log(res);
  // _next()

  if (res) {
    yield put(newRegistrationRequestSuccess());
    console.log(res);
    //   message.success(succMessage);
    //   resetForm();
    // handleModalClose();
    //   yield call(getAllUsersSaga, { payload: { query } });
    //   yield call(getAllOrgsUserSaga, { payload: { id: orgId } });
  }
}

function* addNewRegistrationProfileSetupSaga({
  payload: { formData, user_uuid },
}) {
  console.log("Form-data:", formData, user_uuid);
  // const res = yield call(Api.auth.profileSetup, formData, user_uuid)
  const res = "Request going";
  console.log(res);

  if (res) {
    console.log(res);
    yield put(newRegistrationSetupRequestSuccess({}));
    //   message.success(succMessage);
    //   resetForm();
    // handleModalClose();
    //   yield call(getAllUsersSaga, { payload: { query } });
    //   yield call(getAllOrgsUserSaga, { payload: { id: orgId } });
  }
}

export default function* authSaga() {
  yield takeEvery(newRegistrationRequest.toString(), addNewRegistrationSaga);
  yield takeEvery(
    newRegistrationSetupRequest.toString(),
    addNewRegistrationProfileSetupSaga
  );
  // yield takeLatest(getAllProfileRequest.toString(), getProfilesSaga)
}
