import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    fullname: "",
    phone: "",
    day: "",
    slot_start: 0,
    address: "",
    referral_uuid: "",
    profile_uuid: "",
    selected_services: [],
  },
  error: null,
  status: "idle",
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    newAppointmentRequest(state, action) {
      state.status = "loading";
    },
    newAppointmentRequestSuccess(state, action) {
      state.data = action.payload;
      state.status = "completed";
      state.error = null;
    },
    newAppointmentRequestFail(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
  },
});

export const {
  newAppointmentRequest,
  newAppointmentRequestSuccess,
  newAppointmentRequestFail,
} = appointmentSlice.actions;

export default appointmentSlice;
