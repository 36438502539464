import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  status: "idle",
};

const profileSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    profilesRequest(state, action) {
      state.status = "loading";
    },
    profilesRequestSuccess(state, { payload }) {
      state.data = payload;
      state.status = "completed";
      state.error = null;
    },
    profilesRequestFail(state, { payload }) {
      state.error = payload;
      state.status = "error";
    },
  },
});

export const { profilesRequest, profilesRequestSuccess, profilesRequestFail } =
  profileSlice.actions;

export default profileSlice;
