import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  specialtiesRequest,
  specialtiesRequestSuccess,
  specialtiesRequestFail,
} from '../slices/sys_specialties'

import pathSysSpecialties from '../api/sys_specialties'
import Api from '../api'


export function* getAllSpecialitySaga() {
  try {
    const response = yield call(Api.specialties.list)
    yield put(specialtiesRequestSuccess(response.entities.specialties))
  } catch (error) {
    yield put(
      specialtiesRequestFail('There was an error trying to get the request.'),
    )
  }
}

export default function* sysSpecialtiesSaga() {
  yield takeEvery(specialtiesRequest.toString(), getAllSpecialitySaga)
}
