import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaMapMarkerAlt, FaUser } from "react-icons/fa";

import Stack from "react-bootstrap/Stack";
import GoogleAutoComplete from "./GoogleAutoComplete";

const Search = ({ specialties, onSelectSpecialty, onSelectProvince }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionProvince, setSelectedOptionProvince] = useState("");

  const handleSelectSpecialty = (event) => {
    setSelectedOption(event.target.value);
    if (onSelectSpecialty) {
      onSelectSpecialty(event.target.value);
    }
  };

  const handleSelectProvince = (event) => {
    setSelectedOptionProvince(event);
    if (onSelectProvince) {
      onSelectProvince(event);
    }
  };
  return (
    <>
      <Form className="search-form mt-3">
        <Stack direction="horizontal" className="search-container">
          <Stack direction="horizontal" className="input-wrapper">
            <InputGroup>
              <InputGroup.Text>
                <FaUser />
              </InputGroup.Text>

              <Form.Select
                style={{ border:"none", ":focusVisible ":"none" }}
                aria-label="Parrucchiere"
                onChange={handleSelectSpecialty}
                value={selectedOption}
              >
                <option value="" disabled>
                  Scegli un'area
                </option>
                {specialties.map((speciality, index) => (
                  <option key={index} value={speciality.value}>
                    {speciality.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            <div className="divider vertical"></div>

            <InputGroup className="custome-input">
              <InputGroup.Text>
                <FaMapMarkerAlt />
              </InputGroup.Text>
              <GoogleAutoComplete onChangeProvince={handleSelectProvince} />
            </InputGroup>
          </Stack>
          {/* <button
            type="submit"
            className="search-btn theme-btn rounded d-flex gap-1 align-items-center"
          >
            <FiSearch /> <span className="d-none d-md-inline">Cerca</span>
          </button> */}
        </Stack>
      </Form>
    </>
  );
};

export default Search;
