import { http } from "helpers2impact";
import config from "../../config";

const pathAppointment = (hostname) => {
  return {
    // createAppointment: (formData, profileUuid) =>
    //   `${hostname}/public/booking/profiles/${profileUuid}`,
    createAppointment: (formData, profileUuid) =>
      http(config.cookieName, config.loginPath).post(
        `${hostname}/appointment/${profileUuid}`,
        formData
      ),
  };
};
export default pathAppointment;
