import { Container } from "react-bootstrap";
import Search from "./Search";

const HeroSection = ({
  specialties,
  provinces,
  onSelectSpecialty,
  onSelectProvince,
}) => {
  return (
    <section className="hero-section">
      <Container className="hero-container">
        <div className="d-flex flex-column align-items-center align-items-lg-start px-2">
          <h4 className="hero-text">
            Trova ora il tuo professionista di <i>fiducia.</i>
          </h4>
          <Search
            specialties={specialties}
            provinces={provinces}
            onSelectSpecialty={onSelectSpecialty}
            onSelectProvince={onSelectProvince}
          />
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
