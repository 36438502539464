import { http } from "helpers2impact";
import { profile as mockedData } from "../mocks/profile";
import config from "../../config";

const ApiConfig = {
  version: "v20181007",
  mockedData: false,
};

const profileApi = (hostname) => {
  return {
    show: (slug) => {
      let apiUrl = `${hostname}/public/profiles/${slug}`;
      return ApiConfig.mockedData
        ? mockedData
        : http(config.cookieName, config.loginPath).get(apiUrl);
    },
    list: async (specialty = "", province = "") => {
      let apiUrl = `${hostname}/public/profiles/type/artist`;
      if (specialty !== "") {
        console.log("specialty", specialty);
        apiUrl += `?specialties=${specialty}`;
      } else if (province !== "") {
        apiUrl += `?city=${province}`;
      }
      return http(config.cookieName, config.loginPath).get(apiUrl);
    },
  };
};

export default profileApi;
