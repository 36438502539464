import { put, call, takeEvery } from 'redux-saga/effects'

import {
  provincesRequest,
  provincesRequestSuccess,
  provincesRequestFail,
} from '../slices/sys_provinces'
//import pathProvinces from '../api/provinces'

import { dropdownCities } from '../../store/mocks/cities'

function* listProvincesSaga() {
  try {
    //const response = yield call(pathProvinces.list);
    const response = dropdownCities
    yield put(provincesRequestSuccess(response))
  } catch (error) {
    yield put(provincesRequestFail(error))
  }
}

export default function* provincesSaga() {
  yield takeEvery(provincesRequest.toString(), listProvincesSaga)
}
