import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  status: "idle",
};

const slotSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    slotRequest(state, action) {
      state.status = "loading";
    },
    slotRequestSuccess(state, { payload }) {
      state.data = payload;
      state.status = "completed";
      state.error = null;
    },
    slotRequestFail(state, { payload }) {
      state.error = payload;
      state.status = "error";
    },
  },
});

export const { slotRequest, slotRequestSuccess, slotRequestFail } =
  slotSlice.actions;

export default slotSlice;
