import { call, put, takeLatest } from "redux-saga/effects";

import {
  serviceRequest,
  serviceRequestFail,
  serviceRequestSuccess,
} from "../slices/services";

import Api from "../api";

function* serviceListSaga({ payload: { profileUuid } }) {
  try {
    const response = yield call(Api.services.list, profileUuid);
    yield put(
      serviceRequestSuccess(response.data.entities.services.items.data)
    );
  } catch (error) {
    yield put(serviceRequestFail("showProfilesSaga error", error));
  }
}

export default function* serviceSaga() {
  yield takeLatest(serviceRequest.toString(), serviceListSaga);
}
