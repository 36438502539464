import React, { useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import { useDispatch, useSelector } from "react-redux";

import PlaceholderServicesList from "../../components/Services/Placeholder";
import { serviceRequest } from "../../store/slices/services";

import ServiceCard from "./Card";
import MySpinner from "../Spinner";

const ServicesList = ({
  profileUuid,
  selectedServices,
  onSelectService,
  openModal,
  isModalOpen,
  children,
}) => {
  const dispatch = useDispatch();

  const { data: services, status: serviceStatus } = useSelector(
    (state) => state.Services
  );

  useEffect(() => {
    dispatch(
      serviceRequest({
        profileUuid,
      })
    );
  }, [dispatch, profileUuid]);

  const selectService = (service) => {
    onSelectService([...selectedServices, service]);
  };

  const deselectService = (service) => {
    const serviceIndex = selectedServices.findIndex(
      (listService) => listService.uuid === service.uuid
    );
    onSelectService([
      ...selectedServices.slice(0, serviceIndex),
      ...selectedServices.slice(serviceIndex + 1),
    ]);
  };

  if (serviceStatus !== "completed") return <MySpinner />;

  return (
    <ReactPlaceholder
      ready={serviceStatus === "completed"}
      type="textRow"
      color="#E0E0E0"
      showLoadingAnimation
      customPlaceholder={
        <PlaceholderServicesList
          repeat={3}
          pic_service="https://dummyimage.com/45x45/0011ff/000000.png&text=Service"
        />
      }
    >
      <section id="services">
        {services.length > 0 && services.map((service, i) => (
            <div className="d-flex flex-row" key={"service_" + i}>
              <ServiceCard
                service={service}
                onServiceSelect={() => selectService(service)}
                onServiceDeselect={() => deselectService(service)}
                openModal={openModal}
                isModalOpen={isModalOpen}
                key={"service_" + i}
              />
              <div className="">{children}</div>
            </div>
        ))}
      </section>
    </ReactPlaceholder>
  );
};

export default ServicesList;
