import React, { useState } from 'react'

import moment from 'moment'

import WizardForm from './Wizard/WizardForm'
import { WizardTab } from '../../components'
import StepOne from './Form/step1'
import StepTwo from './Form/step2'

// import 'antd-mobile/dist/antd-mobile.css'

const Registration = () => {
  const [modalOpened, setModalOpened] = useState(false) // set with the modal name

  const [customerFullname, setCustomerFullname] = useState(null)
  const [customerPhone, setCustomerPhone] = useState(null)
  const [selectedDay, setSelectedDay] = useState(moment().format('YYYY-MM-DD')) // today is selected by default
  const [selectedBlock, setSelectedBlock] = useState(null) // booking time block
  const [selectedServices, setSelectedServices] = useState([]) // booking services
  //const [customerNotes, setCustomerNotes] = useState(null)

  //   const { data: profile, status: profileStatus } = useSelector(
  //     (state) => state.Profile,
  //   )

  const [currentStep, setCurrentStep] = useState(0)

  const _next = () => setCurrentStep(currentStep + 1)
  const _prev = () => setCurrentStep(currentStep - 1)
  // const [isLoading, error, profile] = API.useFetch(API.profile.show(this.props.match.params.slug));

  // open when his name is setted, closed when status is false
  const openModal = (name) => {
    setModalOpened(name)
    console.log('openModal: ' + name)
  }

  // Tab lista servizi

  // tab riepilogo - debounce adopted inside the component

  //   if (profileStatus !== 'completed') return 'Loading...' //<Loader />;

  return (
    <WizardForm
      _final={() => openModal('login')}
      _next={_next}
      currentStep={currentStep}
    >
      <WizardTab
        title={'Personal Information'}
        subtitle="Seleziona la prestazione"
        component={<StepOne _next={_next} />}
        actions={{
          displayOn: selectedServices.length > 0,
          subtext: "per selezionare la data dell'appuntamento",
        }}
        _next={_next}
      />
      <WizardTab
        title="Specialites"
        subtitle="Seleziona un giorno e l'orario"
        component={<StepTwo />}
        actions={{
          displayOn: selectedBlock && Object.keys(selectedBlock).length > 0,
          subtext: 'per verificare i dati della tua prenotazione',
        }}
      />
      <WizardTab
        title="Done"
        subtitle="Controlla i dati"
        component={<div className="row">Done</div>}
        actions={{
          displayOn: selectedServices.length > 0 && selectedBlock !== null,
          subtext: 'Procedi per confermare la tua prenotazione in agenda',
        }}
      />
    </WizardForm>
  )
}

export default Registration
