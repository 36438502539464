// import { reviews as mockedData } from '../mocks/reviews';

export default function specialties(hostname) {
  return {
    list: async (limit = 200, skip = 0) => {
      const url = `${hostname}/sys/profiles/specialties`
      const response = await fetch(url)
      const data = await response.json()
      return data
    },
  }
}
