import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <footer className="pt-4 my-md-4 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
              <img className="brand-logo mb-2" src="./logo-black.webp" alt="logo biutify" />
              <small className="d-block mb-3 text-muted">
                &copy; 2018-2023 - biutify &eacute; un progetto realizzato da Rebel Codes srl<br />P.IVA 05560870874 - Cap. soc. iv 30.000 euro
              </small>
            </div>
          {/* <div className="col-6 col-md-3">
              <h5>Azienda</h5>
              <ul className="list-unstyled text-small">
                <li><a className="text-muted" href="/about/chi-siamo">Chi siamo</a></li>
                <li><a className="text-muted" href="/careers/">Carriere</a></li>
                <li><a className="text-muted" href="/about/presskit">PressKit</a></li>
              </ul>
            </div> */}
          {/* <div className="col-6 col-md-3">
            <h5>Assistenza clienti</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="/wp/help/faq">
                  Domande comuni
                </a>
              </li>
              <li>
                <a className="text-muted" href="/wp/help/assistenza">
                  Assistenza
                </a>
              </li>
              <li><a className="text-muted" href="/downloads/ios">Download per iPhone</a></li>
              <li><a className="text-muted" href="/download/android">Download per Android</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3">
            <h5>Per i Professionisti</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="/wp/">
                  Apri un account Pro
                </a>
              </li>
              <li>
                <a className="text-muted" href="/wp/business/partners-stories">
                  Storie di successo
                </a>
              </li>
              <li><a className="text-muted" href="/business/affiliation-program">Programma di affiliazione</a></li>
              <li><a className="text-muted" href="/business/influencer-program">Programma Influencers</a></li>
            </ul>
          </div> */}
          <div className="col-6 col-md-3">
            <h5>Informazioni</h5>
            <ul className="list-unstyled text-small">
              <li>
                {/* <a className="text-muted" href="/legal/privacy-policy">Privacy policy</a> */}
                <a
                  href="https://www.iubenda.com/privacy-policy/55166575"
                  className="text-muted iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe mb-2"
                  title="Privacy Policy "
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="text-muted" href="/wp/legal/termini-di-servizio">
                  Termini di servizio
                </a>
              </li>
              {/* <li><a className="text-muted" href="/security/customers">Politica sulla sicurezza</a></li>
                <li><a className="text-muted" href="/security/whitehat-program">Bug bounty program</a></li>https://www.facebook.com/whitehat */}
            </ul>
          </div>
          <div className="col-6 col-md-3">
            <img
              className="mb-2"
              src="/logo_lazioinnova.png"
              alt=""
              width="256"
              height="45"
            />
            <a href="/wp/progetto-supportato-finanziariamente">
              <small className="d-block mb-1 text-muted">
                Scopri il supporto dell'Europa al progetto biutify
              </small>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
