import Rating from "../Rating";
import ThumbnailsGroup from "../CardThumbnailsGroup";

import "./style.css";

const ProfileCard = ({
  profile,
  defaultPicProfile,
  openModalMap,
  openModalReviews,
  openModalThumbs,
  ...rest
}) => {
  const picProfile =
    profile?.user && profile?.user?.pic_profile
      ? profile?.user?.pic_profile?.url
      : defaultPicProfile;

  return (
    <div>
      <img
        className="img-responsive rounded-circle pic-profile-size center-block d-block mx-auto"
        height="100"
        width="100"
        src={picProfile}
        alt={`Immagine profilo di ${profile?.user && profile?.user?.fullname}`}
      />
      <div className="text-center">
        <h1 className="text-truncated mt-2 mb-1 clearfix">
          {profile?.user && profile?.user?.fullname}
          {profile?.certified_at && (
            <span className="pl-1 text-primary">
              <svg
                width="0.5em"
                height="0.5em"
                viewBox="0 0 16 16"
                className="bi bi-shield-fill-check"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 .5c-.662 0-1.77.249-2.813.525a61.11 61.11 0 0 0-2.772.815 1.454 1.454 0 0 0-1.003 1.184c-.573 4.197.756 7.307 2.368 9.365a11.192 11.192 0 0 0 2.417 2.3c.371.256.715.451 1.007.586.27.124.558.225.796.225s.527-.101.796-.225c.292-.135.636-.33 1.007-.586a11.191 11.191 0 0 0 2.418-2.3c1.611-2.058 2.94-5.168 2.367-9.365a1.454 1.454 0 0 0-1.003-1.184 61.09 61.09 0 0 0-2.772-.815C9.77.749 8.663.5 8 .5zm2.854 6.354a.5.5 0 0 0-.708-.708L7.5 8.793 6.354 7.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
                />
              </svg>
            </span>
          )}
        </h1>

        <div className="px-3 mt-2">
          {profile?.specialties &&
            profile?.specialties?.map((speciality) => {
              return (
                <span
                  key={speciality.slug}
                  className="badge badge-pill badge-primary mr-1"
                >
                  {speciality.name}
                  {speciality.info.verified_at !== null && (
                    <i className="fa fa-check-circle"></i>
                  )}
                </span>
              );
            })}
        </div>

        <div className="mt-2">
          <small>
            <i className="fa fa-map-marker"></i>&nbsp;
            {/* <a
              href={"#indirizzo"}
              title={`Indirizzo di ${profile?.user && profile?.user.fullname}`}
              className="link"
              onClick={openModalMap}
            > */}
              {`${profile?.user && profile?.user?.position?.city}.`}
            {/* </a> */}
          </small>
          <br />
          <small>
            {profile?.reviews_received_count > 0 && (
              <>
                <Rating
                  rating={Math.round(
                    profile.reviews_received_score /
                      profile.reviews_received_count
                  )}
                  maxValue={5}
                />
                &nbsp; (
                {/* <a
                  href={'#recensioni'}
                  className="link"
                  onClick={openModalReviews}
                > */}
                  {profile.reviews_received_count})
                {/* </a> */}
              </>
            )}
          </small>
        </div>

        <div className="mt-3">
          <ThumbnailsGroup
            thumbnails={profile?.portfolios}
            openModal={openModalThumbs}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
