import React from 'react';


export default function PlaceholderProfile({pic_profile}) {
    return (
        <div className="card p-2 clearfix d-flex flex-row">
            <img className="img image pull-left" height="100" width="100" src={pic_profile} alt="pic-profile" />
            <div className="pull-right w-100 pl-3">                        
                <div className="placeholderText" />
                <div className="placeholderText" />
                <div className="placeholderText" />
                <div className="placeholderText" />
            </div>
        </div>
    )
}