import moment from 'moment'

// Genero l'array dei giorni di una settimana
const getDaysOfWeek = (weekStartDay, weekEndDay) => {
  //new Promise( (resolve, reject) => {
  console.log('useDaysOfWeek... invoked.')
  // console.log("weekStartDay "+weekStartDay.format('YYYY-MM-DD'));
  // console.log("weekEndDay "+weekEndDay.format('YYYY-MM-DD'));
  let daysArray = []
  let day = weekStartDay
  while (day <= weekEndDay) {
    daysArray.push(day)
    day = day.clone().add(1, 'd')
  }
  return daysArray //resolve(daysArray);
}

// Genero dei blocchi da far visualizzare ai consumer dell'agenda digitale
// dalle ore 7:00 alle ore 23:00 = 64 blocchi da 15 minuti
const getBlocks = (startTime = '07:00', quantity = 64, duration = 15) => {
  console.log('useBlocksGenerator... invoked.')
  if (startTime == '07:00' && quantity === 64 && duration === 15) {
    console.log('using optimized block pre-generated.')
    return [
      { label: '07:00' },
      { label: '07:15' },
      { label: '07:30' },
      { label: '07:45' },
      { label: '08:00' },
      { label: '08:15' },
      { label: '08:30' },
      { label: '08:45' },
      { label: '09:00' },
      { label: '09:15' },
      { label: '09:30' },
      { label: '09:45' },
      { label: '10:00' },
      { label: '10:15' },
      { label: '10:30' },
      { label: '10:45' },
      { label: '11:00' },
      { label: '11:15' },
      { label: '11:30' },
      { label: '11:45' },
      { label: '12:00' },
      { label: '12:15' },
      { label: '12:30' },
      { label: '12:45' },
      { label: '13:00' },
      { label: '13:15' },
      { label: '13:30' },
      { label: '13:45' },
      { label: '14:00' },
      { label: '14:15' },
      { label: '14:30' },
      { label: '14:45' },
      { label: '15:00' },
      { label: '15:15' },
      { label: '15:30' },
      { label: '15:45' },
      { label: '16:00' },
      { label: '16:15' },
      { label: '16:30' },
      { label: '16:45' },
      { label: '17:00' },
      { label: '17:15' },
      { label: '17:30' },
      { label: '17:45' },
      { label: '18:00' },
      { label: '18:15' },
      { label: '18:30' },
      { label: '18:45' },
      { label: '19:00' },
      { label: '19:15' },
      { label: '19:30' },
      { label: '19:45' },
      { label: '20:00' },
      { label: '20:15' },
      { label: '20:30' },
      { label: '20:45' },
      { label: '21:00' },
      { label: '21:15' },
      { label: '21:30' },
      { label: '21:45' },
      { label: '22:00' },
      { label: '22:15' },
      { label: '22:30' },
      { label: '22:45' },
    ]
  }
  let time = moment(startTime, 'HH:mm')
  return [...new Array(quantity)].map((slot, i) => {
    if (i > 0) time.add(duration, 'minutes')
    return { label: time.format('HH:mm') }
  })
}

// Verifico che all'interno degli slot dell'agenda, il blocco selezionato sia disponibile
// slots from be - 5 minutes duration - is a string like 1111111111111111100000000000000000000000000000000000000000000000000000000000
// blocks from fe - 15 minutes durations - is an array like [{"label":"07:00"},{"label":"07:15"},{"label":"07:30"},{"label":"07:45"}]
const getAvailableBlocks = (blocks, slots) => {
  // new Promise( (resolve, reject) => {
  console.log('useAvailableBlocks... invoked.')

  return blocks.map((block, index) => {
    //( parseInt(slots[index]) + parseInt(slots[index + 1]) + parseInt(slots[index + 2]) === 0)
    let sizeBlock = index === 0 ? 0 : index * 3 // Each block is formed by 3 slots (5 minutes each)
    let time = block.label

    return {
      label: time,
      disable:
        parseInt(slots[sizeBlock]) +
          parseInt(slots[sizeBlock + 1]) +
          parseInt(slots[sizeBlock + 2]) ===
        0,
    }
  })
}

export { getDaysOfWeek, getBlocks, getAvailableBlocks }
