import { combineReducers } from "redux";
import {
  AuthSlice,
  ProfileSlice,
  ProfileDetailsSlice,
  SpecialitySlice,
  ProvinceSlice,
  ReviewSlice,
  ServiceSlice,
  SlotSlice,
  AppointmentSlice,
} from "./slices";

const allReducers = combineReducers({
  Auth: AuthSlice.reducer,
  Profiles: ProfileSlice.reducer,
  ProfileDetails: ProfileDetailsSlice.reducer,
  Speciality: SpecialitySlice.reducer,
  Province: ProvinceSlice.reducer,
  Reviews: ReviewSlice.reducer,
  Services: ServiceSlice.reducer,
  Slots: SlotSlice.reducer,
  Appointment: AppointmentSlice.reducer,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

export default rootReducer;
