import { CSSTransition } from 'react-transition-group'

const Wizard = ({
  currentStep,
  display,
  stepsCount,
  subtext,
  _next,
  _prev,
  _final,
  selectedServices,
  selectedBlock,
}) => {
  return (
    // <CSSTransition
    //     in={display} // important: display only if display var is true
    //     classNames="fade"
    //     timeout={500}
    //     unmountOnExit
    // >
    <div className="fixed-bottom">
      <div className="p-1 px-2 border border-primary border-highlighted rounded bg-white">
        <div className="steps-action text-center">
          {currentStep < stepsCount - 1 && (
            <>
              {currentStep === 0 &&
                selectedServices &&
                selectedServices.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-block p-1"
                      onClick={() => _next()}
                    >
                      Prosegui <i className="fas fa-long-arrow-alt-right"></i>
                      <br />
                      <span className="text-white">
                        <small>{subtext}</small>
                      </span>
                    </button>
                  </>
                )}
              {currentStep === 1 && selectedBlock !== null && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary btn-block p-1"
                    onClick={() => _next()}
                  >
                    Prosegui <i className="fas fa-long-arrow-alt-right"></i>
                    <br />
                    <span className="text-white">
                      <small>{subtext}</small>
                    </span>
                  </button>
                </>
              )}
            </>
          )}
          {
            currentStep === stepsCount - 1 && false
            //(
            //     <button type="button" className="btn btn-primary btn-block p-2" onClick={() => _final()}>
            //         <i className="fa fa-calendar" aria-hidden="true"></i> Prenota
            //     </button>
            // )
          }
          {currentStep > 0 && (
            <div className="my-2">
              oppure{' '}
              <a href="#indietro" title="indietro" onClick={() => _prev()}>
                <i className="fas fa-long-arrow-alt-left"></i> Torna indietro
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
    // </CSSTransition>
  )
}

export default Wizard
