import { http } from "helpers2impact";

const config = {
  version: "v20181007",
  mockedData: false,
};

export default function pathServices(hostname) {
  return {
    list: async (profileUuid, limit = 200, skip = 0) => {
      const url = `${hostname}/profiles/${profileUuid}/services?limit=${limit}&skip=${skip}`;
      return http(config.cookieName, config.loginPath).get(url);
    },
  };
}
