import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./style.css";
import { useDispatch } from "react-redux";
import { newAppointmentRequest } from "../../store/slices/appointment";

const loginValidator = yup.object({
  fullname: yup.string().required("Campo necessario!").min(4),
  phone: yup.number().required("Campo necessario!").positive(),
});

const FormConfirmAppointment = ({ profile_uuid }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, touchedFields, isSubmitted },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      fullname: "",
      phone: "",
      tos: true,
    },
    resolver: yupResolver(loginValidator),
  });

  const onSubmit = (formData) => {
    console.log("onSubmit: ", JSON.stringify(formData));
    dispatch(
      newAppointmentRequest({
        formData: { ...formData },
        profile_uuid,
      })
    );
    // Actions.createAppointment(formData, history, profile ? true : false);
  };

  const isFormValid =
    isDirty &&
    Object.keys(touchedFields).length > 0 &&
    Object.keys(errors).length === 0;

  return (
    <div className="row mt-3">
      <div className="col-12">
        <form
          className={
            !isSubmitted
              ? "needs-validation"
              : isFormValid
              ? "was-validated"
              : ""
          }
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <div className="form-row">
            <InputField
              label="Il tuo nome e cognome"
              type="text"
              name="fullname"
              placeholder="Federica Rossi"
              register={register}
              error={errors.fullname}
              touched={touchedFields.fullname}
            />
            <InputField
              label="Il tuo numero di telefono"
              type="text"
              name="phone"
              placeholder="3471134202"
              register={register}
              error={errors.phone}
              touched={touchedFields.phone}
              hint="Il tuo numero verrà usato solo ai fini di questa prenotazione."
            />
          </div>
          <div className="form-group form-check mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              {...register("tos")}
            />
            <label className="form-check-label" htmlFor="tos">
              <small className="text-dark">
                Autorizzo il trattamento secondo quanto previsto dalla normativa
                corrente sulla privacy.
              </small>
            </label>
          </div>
          <button
            className="btn btn-block btn-success mt-2"
            type="submit"
            disabled={!isFormValid}
          >
            Conferma la tua prenotazione
          </button>
        </form>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  type,
  name,
  placeholder,
  register,
  error,
  touched,
  hint,
}) => (
  <div className="col">
    <label className="form-control-label" htmlFor={name}>
      {label}
    </label>
    <input
      className={`form-control ${error ? "is-invalid" : ""}`}
      type={type}
      name={name}
      placeholder={placeholder}
      aria-invalid={error ? "true" : "false"}
      {...register(name)}
    />
    {hint && <small className="form-text text-muted">{hint}</small>}
    {touched && error && (
      <div className="invalid-feedback">{error.message}</div>
    )}
  </div>
);

export default FormConfirmAppointment;
