import { call, put, takeEvery } from "redux-saga/effects";
import Api from "../api";
import {
  newAppointmentRequest,
  newAppointmentRequestSuccess,
} from "../slices/appointment";

function* newAppointmentSaga({ payload: { formData, profile_uuid } }) {
  console.log("Form-data:", formData, profile_uuid);
  //const res = yield call(Api.appointment.createAppointment, formData, user_uuid)
  const res = "Request going";
  console.log(res);

  if (res) {
    console.log(res);
    yield put(newAppointmentRequestSuccess({}));
    //   message.success(succMessage);
    //   resetForm();
    // handleModalClose();
    //   yield call(getAllUsersSaga, { payload: { query } });
    //   yield call(getAllOrgsUserSaga, { payload: { id: orgId } });
  }
}

export default function* bookingSaga() {
  yield takeEvery(newAppointmentRequest.toString(), newAppointmentSaga);
  // yield takeLatest(getAllProfileRequest.toString(), getProfilesSaga)
}
