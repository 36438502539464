import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  error: null,
  status: 'idle', // "idle", "loading", "completed"
}

const sysProvincesSlice = createSlice({
  name: 'sys_provinces',
  initialState,
  reducers: {
    provincesRequest(state) {
      state.status = 'loading'
    },
    provincesRequestSuccess(state, action) {
      state.data = action.payload
      state.status = 'completed'
    },
    provincesRequestFail(state, action) {
      state.error = action.payload
      state.status = 'idle'
    },
  },
})

export const {
  provincesRequest,
  provincesRequestSuccess,
  provincesRequestFail,
} = sysProvincesSlice.actions

export default sysProvincesSlice
