import React from 'react';


export default function Rating({rating, maxValue}) {

    maxValue = parseInt(maxValue);
    //console.log("rating: "+rating)
    let stars = [];

    for(let i=0; i < maxValue; i++) {

        let star;

        if(i+1 <= Math.trunc(rating)) {
            star = <i key={i} className="text-warning fa fa-star"></i>;
        }
        else if(((rating - Math.floor(rating)) !== 0) && i+1 === Math.trunc(rating + 1)) {
            star = <i key={i} className="text-warning fa fa-star-half-alt"></i>;
        }
        else star = <i key={i} className="text-warning far fa-star"></i>;
    
        stars.push(star);

        if(maxValue===stars.length) return (<span style={{minWidth:74.3}}>{stars}</span>);
  
    }

    // return (
    //     <>
    //         <i className="text-warning fa fa-star"></i>&nbsp;{parseFloat(rating).toFixed(0)}
    //     </>
    // );
}