import "./style.css";

const CardThumbnailsGroup = ({ thumbnails, openModal }) => {
  return (
    <div className="avatar-group">
      {thumbnails.map((thumb, i) => (
        <a
          key={`thumbnail_${thumb.uuid}_${i}`}
          className="avatar rounded-circle"
          href="#portfolio"
          onClick={openModal}
        >
          <img src={thumb.url} width="50" height="50" alt={thumb.label} />
        </a>
      ))}
    </div>
  );
};

CardThumbnailsGroup.defaultProps = {
  thumbnails: [],
};

export default CardThumbnailsGroup;
