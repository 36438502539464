import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import './styles/dayButton.css'


const setDayStyle = (isSelectedDay, isClickable, isCurrentDay) => classNames({ 
    'btn day-btn border': true, 
    'btn-primary': isSelectedDay,                   // if is the selected day the color is BLUE
    'btn-success': !isSelectedDay && isClickable,   // if is not the selected day and is an available day the btn color is GREEN
    'btn-light': !isClickable && !isSelectedDay,    // if is not an available and is not the selected day the btn color is GREY
    'font-weight-bold': isCurrentDay,               // if is the current day the day label is BOLD
});


const DayButton = ({dayName, dayNumber, selectedDay, setSelectedDay}) => {

    const today = moment();

    const isSelectedDay = dayNumber.format('YYYY-MM-DD') === selectedDay;
    const isCurrentDay  = dayNumber.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
    const isClickable   = dayNumber.format('YYYY-MM-DD') >= today.format('YYYY-MM-DD');

    return (
        <div className="d-flex flex-column align-items-center">
            <small className="mb-2 text-muted">{dayName}</small>
            <button
                type="button"
                disabled={!isClickable}
                className={setDayStyle(isSelectedDay, isClickable, isCurrentDay)}
                onClick={isClickable ? () => setSelectedDay( moment(dayNumber).format('YYYY-MM-DD')) : null }
            >
                {moment(dayNumber).format("DD")}
            </button>
        </div>
    );

}

export default DayButton;