import { call, put, takeLatest } from "redux-saga/effects";

import {
  slotRequest,
  slotRequestFail,
  slotRequestSuccess,
} from "../slices/slots";

import Api from "../api";

function* slotListSaga({ payload: { profileUuid, from, to } }) {
  try {
    const response = yield call(Api.slot.listSlots, profileUuid, from, to);
    console.log("response-slots:", response);
    yield put(slotRequestSuccess(response.data.entities.slots.items.data));
  } catch (error) {
    yield put(slotRequestFail("showProfilesSaga error", error));
  }
}

export default function* reviewSaga() {
  yield takeLatest(slotRequest.toString(), slotListSaga);
}
