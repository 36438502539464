import React, { PureComponent } from 'react';

import './style.css';

export default class NotFound extends PureComponent {
  render() {
    return (
      <div className="container pt-3">
        <div className="col-sm-12 col-md 6">
          <div className="row d-flex justify-content-center">
            <div className="error-image-container">
              <img 
                className="img img-thumbnail"
                src={"https://seomarketingexperts.nl/wp-content/uploads/2018/08/shutterstock_325494917-5a68d8403418c600190a3e1f.jpg"}
                alt="not-found"
                width="100%"
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <h3>Profilo non trovato</h3>
          </div>
        </div>
      </div>
    );
  }
}

