import profileApi from "./profile";
import pathReviews from "./reviews";
import pathServices from "./services";
import pathSysSpecialties from "./sys_specialties";
import authApi from "./auth";
import pathSlot from "./slot";
import pathAppointment from "./appointment";

const config = {
  env: "production",
  hostname: {
    production: `https://developers.biutify.it/api`,
    development: `http://localhost:3000/api`,
  },
};

const Api = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  auth: authApi(config.hostname[config.env]),
  profile: profileApi(config.hostname[config.env]),
  reviews: pathReviews(config.hostname[config.env]),
  services: pathServices(config.hostname[config.env]),
  specialties: pathSysSpecialties(config.hostname[config.env]),
  appointment: pathAppointment(config.hostname[config.env]),
  slot: pathSlot(config.hostname[config.env]),
};

export default Api;
