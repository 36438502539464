import React, { useState } from 'react'
//import ReactPlaceholder from 'react-placeholder'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import GoogleMapReact from 'google-map-react'

//import LocationMarker from './LocationMarker'
import LocationInfoBox from './LocationInfoBox'

import './style.css'

// const NATURAL_EVENT_WILDFIRE = 8;

// le modals vengono rendirizzate anche quando non aperte
// modificarle nella pagina inserendo la condizione al di fuori
// ed agganciandola ad una portal

const ModalAddressMap = ({
  isModalOpen,
  openModal,
  title,
  coordinates,
  googleMapKey,
  zoom,
}) => {
  console.log('ModalAddressMap - isModalOpen: ' + isModalOpen)

  const [locationInfo, setLocationInfo] = useState(null)

  if (!coordinates) return null

  // const markers = coordinates.map((point, index) => {
  //     // if(ev.categories[0].id === NATURAL_EVENT_WILDFIRE) {
  //         return <LocationMarker key={index} lat={point[0]} lng={point[1]} onClick={() => setLocationInfo({ id: point.id, title: point.title })} />
  //     //}
  //     //return null
  // })

  const LocationMarker = ({ lat, lng }) => (
    <div className="marker">
      <i
        className="fa fa-map-marker text-danger marker-size"
        lat={lat}
        lng={lng}
      ></i>
    </div>
  )

  return (
    <Modal
      size="xl"
      backdrop={true}
      centered
      isOpen={isModalOpen}
      toggle={() => openModal(null)}
    >
      <ModalHeader toggle={() => openModal(null)}>
        {title}
        <button className="modal-close-btn" onClick={() => openModal(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="overflow-auto render-modal-body">
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapKey }}
          defaultCenter={{ lat: coordinates[0], lng: coordinates[1] }}
          defaultZoom={zoom}
        >
          <LocationMarker lat={coordinates[0]} lng={coordinates[1]} />
        </GoogleMapReact>
        {locationInfo && <LocationInfoBox info={locationInfo} />}
      </ModalBody>
    </Modal>
  )
}

ModalAddressMap.defaultProps = {
  title: 'title',
  // center: {
  //     lat: 42.3265,
  //     lng: -122.8756
  // },

  zoom: 6,
}

export default ModalAddressMap
