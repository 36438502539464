import React, { PureComponent } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import renderHTML from 'react-render-html';

import { customFetch as fetch } from '../../utils/network';

import './style.css';

export default class ModalHtmlRender extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      content: ''
    }
  }

  componentDidMount = async () => {
    await fetch(this.props.url)
    .then(response => {
    	if(response != null) this.setState({content: response.content});
    	else this.setState({content: ''});
    })
    .catch(error => this.setState({content: ''}));
  }

  render(){
    return (
      <Modal backdrop={true} toggle={this.props.toggleModal} isOpen={this.props.isModalOpen} centered>
        <ModalHeader toggle={this.props.toggleModal}>
          {this.props.title}
        </ModalHeader>
        <ModalBody className="overflow-auto render-modal-body">
          {/* {renderHTML(this.state.content)} */}
          Pippo
        </ModalBody>
      </Modal>
    );
  }
}