import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import ProfileCards from "./ProfileCards";
import AppConfig from "../../config";
import { ModalAddressMap, ModalMasonry, ModalReviews } from "..";

const ProfessionalsSection = ({ profiles }) => {
  const [selectedProfileIndex, setSelectedProfileIndex] = useState({
    selectedProfileIndex: null, // the index of the profile witch user clicked on
  });
  const [modalOpened, setModalOpened] = useState({
    modalOpened: null, // set with the modal name
  });
  const openModal = (name, profileIndex = null) => {
    setModalOpened(name);
    setSelectedProfileIndex(profileIndex);
  };

  return (
    <>
      <section className="professionals-section mt-4">
        <Container>
          <div className="professionals-wrapper">
            <Row>
              {profiles?.length > 0 &&
                profiles.map((professional, index) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    key={`profile-${professional.slug}`}
                    className="professional-column"
                  >
                    <ProfileCards
                      profile={professional}
                      defaultPicProfile={AppConfig.ui.defaultPicProfile}
                      openModalThumbs={() => openModal("masonry", index)}
                      openModalMap={() => openModal("map", index)}
                      openModalReviews={() => openModal("reviews", index)}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </section>
      {modalOpened === "masonry" && (
        <ModalMasonry
          isModalOpen={modalOpened === "masonry"}
          openModal={openModal}
          title={
            profiles[selectedProfileIndex] &&
            `Portfolio dei servizi di ${profiles[selectedProfileIndex].user.fullname}`
          }
          images={
            profiles[selectedProfileIndex] &&
            profiles[selectedProfileIndex]?.portfolios
          }
        />
      )}
      {modalOpened === "map" && (
        <ModalAddressMap
          isModalOpen={modalOpened === "map"}
          openModal={openModal}
          title={
            profiles[selectedProfileIndex] &&
            `Indirizzo di ${profiles[selectedProfileIndex].user.fullname}`
          }
          coordinates={
            profiles[selectedProfileIndex] &&
            profiles[selectedProfileIndex].user.position.point.coordinates
          }
          googleMapKey={AppConfig.services.google.maps_api_key}
          zoom={16}
        />
      )}
      {modalOpened === "reviews" && profiles?.length > 0 && (
        <ModalReviews
          isModalOpen={modalOpened === "reviews"}
          openModal={openModal}
          title={
            profiles[selectedProfileIndex] &&
            `Recensioni ricevute da ${profiles[selectedProfileIndex].user.fullname}`
          }
          profileUuid={
            profiles[selectedProfileIndex] &&
            profiles[selectedProfileIndex].uuid
          }
        />
      )}
    </>
  );
};

export default ProfessionalsSection;
