import Helmet from 'react-helmet';



const MetaInfo = ({title, description, canonical}) => {

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonical} />
        </Helmet>
    );
};

export default MetaInfo;