import React, { useEffect, useMemo } from 'react'
import { Button, Col, Form, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { specialtiesRequest } from '../../../store/slices/sys_specialties'
import { newRegistrationSetupRequest } from '../../../store/slices/auth'
const { Option } = Select

const StepTwo = () => {
  const dispatch = useDispatch()
  const { data: specialties, status: specialitiesDataLoadingStatus } =
    useSelector((state) => state.Speciality)

  useEffect(() => {
    dispatch(specialtiesRequest())
  }, [dispatch])

  const dropdownSpecialties = useMemo(() => {
    return (
      specialties?.map((item) => ({
        value: item?.slug,
        label: item?.name,
      })) || []
    )
  }, [specialties])

  const onFinish = (values) => {
    console.log('Success:', values)
    // _next()
    dispatch(
      newRegistrationSetupRequest({
        formData: { ...values },
        user_uuid: '1',
      }),
    )
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      style={{
        maxWidth: 500,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="speciality"
            label="Speciality"
            rules={[
              {
                required: true,
                message: 'Please select speciality!',
                type: 'array',
              },
            ]}
            // style={{ width: '100vh' }}
          >
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              placeholder="Please select specialities"
            >
              {dropdownSpecialties.map((speciality) => (
                <Option key={speciality.value} value={speciality.value}>
                  {speciality.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="services"
            label="Services"
            rules={[
              {
                required: true,
                message: 'Please select services!',
                type: 'array',
              },
            ]}
            //style={{ width: '100vh' }}
          >
            <Select mode="multiple" placeholder="Please select services">
              <Option value="red">Red</Option>
              <Option value="green">Green</Option>
              <Option value="blue">Blue</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          style={{ backgroundColor: '#c3b092' }}
          block
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
export default StepTwo
