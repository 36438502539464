import moment from 'moment'
import debounce from 'lodash.debounce'

import './style.css'

const CardAppointment = ({
  selectedServices,
  selectedDay,
  selectedBlock,
  fullname,
  address,
  openModal,
  updateNotes,
}) => {
  const appointmentStartAt = moment(
    `${selectedDay} ${selectedBlock?.label}`,
    'YYYY-MM-DD HH:mm',
  )
  const startAt = appointmentStartAt !== null ? appointmentStartAt : moment()

  const totalDuration = selectedServices.reduce(
    (total, service) => (total += service.duration),
    0,
  )
  const totalCost = selectedServices.reduce(
    (total, service) => (total += service.price),
    0,
  )

  return (
    <div className="row">
      <div className="col-12">
        <p>
          <strong>
            <i className="fa fa-clock-o"></i>&nbsp;Data appuntamento: &nbsp;
          </strong>
          giorno {selectedDay} alle ore{' '}
          {` ${startAt.format('HH:mm')} - ${moment(startAt, 'HH:mm')
            .add(totalDuration, 'minutes')
            .format('HH:mm')} `}
        </p>
        <p>
          <strong>
            <i className="fa fa-map-marker"></i>&nbsp;Indirizzo: &nbsp;
          </strong>
          {`${address}.`}&nbsp;
          <a
            href={'#addressMap'}
            className="link"
            onClick={(e) => openModal('map')}
          >
            Mappa
          </a>
        </p>
        <hr />
        <div className="d-flex flex-row align-items-center">
          <strong>
            <i className="fa fa-check"></i>&nbsp;
            <span>{`${selectedServices.length} servizi${
              selectedServices.length === 1 ? 'o' : ''
            } prenotat${selectedServices.length === 1 ? 'o' : 'i'}:`}</span>
          </strong>
        </div>
        <ul>
          {selectedServices?.length > 1 ? (
            selectedServices?.map((service, i) => (
              <li key={i}>
                <span>{service?.default_service?.label}</span>{' '}
                <small>({service.price} euro)</small>
              </li>
            ))
          ) : (
            <span>{selectedServices[0]?.default_service?.label}</span>
          )}
        </ul>
        <span>
          <strong>Informazioni di pagamento:</strong>
          <br />
          <i className="fa fa-euro"></i>&nbsp;
          {`${totalCost} da pagare in contanti`}
        </span>
        <hr />
        <span>{`Aggiungi una nota per ${fullname}`}</span>
        <textarea
          className="form-control mt-1"
          maxLength={255}
          rows={4}
          onChange={debounce((event) => updateNotes(event.target.value), 1000)}
        />
      </div>
    </div>
  )
}

export default CardAppointment
