import React, { useState } from "react";
import Helmet from "react-helmet";

import Hooks from "../../hooks";
import Api from "../../store/api";
import Actions from "../../store/_actions";

import Voucher from "../../components/Voucher/Card";
import WizardForm from "../../components/Wizard/WizardForm";
import WizardTab from "../../components/Wizard/WizardTab";
import ListServices from "../../components/Services/List";

const VoucherPage = ({ match }) => {
  const slug = match.params.slug;
  const [isLoading, error, data] = Hooks.useFetch(Api.profile.show(slug));
  const [selectedServices, setSelectedServices] = useState([]);

  if (isLoading) return <p>is loading</p>;
  const profile = data.entities.profile.item;

  const vouchers = [
    {
      uuid: 1,
      cover_img: {
        src: "https://m.lanuovariviera.it/wp-content/uploads/2015/11/emotion-hair.jpg",
        alt: "Cover promozione novembre 2020",
      },
      title: "Sempre belle da novembre 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      valid_until: "24-12-2020",
      expires_after: 180, // expressed in days
      price: 176,
      price_discount: 176,
      selectedServices: [
        {
          quantity: 2,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 40,
            duration: 40,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Taglio donna",
              description: "Il taglio donna è un taglio particolare bla bla",
              slug: "taglio-donna",
              price_avg: 30,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
        {
          quantity: 6,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 16,
            duration: 20,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Piega donna",
              description: "Lorem ipsum",
              slug: "piega",
              price_avg: 15,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
      ],
      bonusServices: [
        {
          quantity: 6,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 16,
            duration: 20,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Piega donna",
              description: "Lorem ipsum",
              slug: "piega",
              price_avg: 15,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
      ],
    },
    {
      uuid: 2,
      cover_img: {
        src: "https://www.hair-c-coiffure.be/media/images/gallery/136/big/143.jpg",
        alt: "Cover promozione Natale 2020",
      },
      title: "Promozione Capodanno 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      valid_until: "31-12-2020",
      expires_after: 180, // expressed in days
      price: 176,
      price_discount: 176,
      selectedServices: [
        {
          quantity: 4,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 40,
            duration: 40,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Taglio donna",
              description: "Il taglio donna è un taglio particolare bla bla",
              slug: "taglio-donna",
              price_avg: 30,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
        {
          quantity: 2,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 40,
            duration: 40,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Colore donna",
              description: "bla bla",
              slug: "colore-donna",
              price_avg: 30,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
        {
          quantity: 8,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 16,
            duration: 20,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Piega donna",
              description: "Lorem ipsum",
              slug: "piega",
              price_avg: 15,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
      ],
      bonusServices: [
        {
          quantity: 8,
          service: {
            uuid: "95fbfb24-ce80-409e-8f42-62332b4bcecb",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            price: 16,
            duration: 20,
            reviews_count: 0,
            reviews_score: 0,
            default_service: {
              uuid: "a988b29c-5af6-40c9-95fa-d6c89bf89bde",
              label: "Piega donna",
              description: "Lorem ipsum",
              slug: "piega",
              price_avg: 15,
              price_min: 1,
              price_max: 100,
              duration_min: 5,
              duration_avg: 30,
              duration_max: 180,
              category: {
                uuid: "ce9f27c0-2896-4557-b577-cb4d132fdf0f",
                label: "Capelli",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                slug: "capelli",
              },
            },
          },
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{profile.user.fullname}</title>
        <meta
          name="description"
          content={`Agenda appuntamenti di ${profile.user.fullname}`}
        />
        <link
          rel="canonical"
          href={`https://prenota.biuti.it/${profile.slug}/voucher`}
        />
      </Helmet>

      <div className="row justify-content-md-center p-3">
        <div className="col-12 col-md-6">
          {vouchers.map((voucher) => (
            <Voucher key={voucher.uuid + "_voucher"} data={voucher} />
          ))}
        </div>
      </div>

      {/* <WizardForm _final={()=>this.openModal("login")}>
                <WizardTab
                    title={"Servizi"}
                    subtitle="Seleziona i servizi"
                    component={
                        <ListServices
                            profileSlug={slug}
                            selectedServices={selectedServices}
                            onSelectService={onSelectService}
                            setModalOpen={this.openModal}
                            isModalOpen={modalOpened==="imagesMasonry"}
                        >
                        </ListServices>
                    }
                    actions={
                        {
                        displayOn: selectedServices.length>0,
                        subtext: "Verifica i dati"
                        }
                    }
                />
                <WizardTab title="Riepilogo" subtitle="Controlla i dati"
                    component={
                        <>
                            <p>Riepilogo voucher.</p>
                            <p>Crea il voucher</p>
                        </>
                    }
                    actions={
                        {
                        displayOn: (selectedServices.length>0),
                        subtext: "Pubblica il voucher"
                        }
                    }
                />
            </WizardForm> */}
    </>
  );
};

export default VoucherPage;
