import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    uuid: null,
    is_public: null,
    certified_at: null,
    appointments_count: null,
    reviews_made_count: null,
    reviews_made_score: null,
    reviews_received_count: null,
    reviews_received_score: null,
    credit: null,
    trust: null,
    celebrity: null,
    points: null,
    ranking: null,
    type: null, // Artist
    specialties: [], // Massaggiatore, Estetista
  },
  error: null,
  status: "idle",
};

const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    profileDetailsRequest(state, action) {
      state.status = "loading";
    },
    profileDetailsRequestSuccess(state, { payload }) {
      state.data = payload;
      state.status = "completed";
      state.error = null;
    },
    profileDetailsRequestFail(state, { payload }) {
      state.error = payload;
      state.status = "error";
    },
  },
});

export const {
  profileDetailsRequest,
  profileDetailsRequestSuccess,
  profileDetailsRequestFail,
} = profileDetailsSlice.actions;

export default profileDetailsSlice;
