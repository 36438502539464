import React, {useState} from 'react';

import Rating from '../Rating';
import CardThumbnailsGroup from '../CardThumbnailsGroup';

import './card.css';


// const ServiceContext = React.createContext('service');
// <ThemeContext.Provider value="dark"></ThemeContext.Provider>

const ServiceCard = ({
        service: {uuid, description, price, duration, reviews_score, reviews_count, default_service},
        onServiceSelect, onServiceDeselect,
        openModal, isModalOpen
    }) => {

    const [isSelected, setIsSelected] = useState(false);
    const callback = isSelected ? onServiceDeselect : onServiceSelect;

    const callbackOnSelect = () => {
        setIsSelected(!isSelected);
        callback();
    }

    return (
        <div className={`card mt-3 ${isSelected && 'cardSelected'}`}>
            <div className="card-body pb-2">

                <div className="card-title clearfix">
                    <div className="float-left">
                        <h4>{default_service.label}</h4>
                        {
                            isSelected && (<span className="badge badge-pill badge-success"><i className="fas fa-check"></i> Aggiunto al carrello</span>)
                        }
                    </div>
                    <div className="float-right">
                        {
                            isSelected && (
                                <button type="button" className="btn btn-primary btn-sm px-3" onClick={callbackOnSelect}>
                                    <small><i className="fa fa-times"></i></small>&nbsp;&nbsp;Rimuovi
                                </button>
                            )
                        }
                    </div>
                </div>

                <div className="card-subtitle clearfix text-muted">
                    {
                        reviews_count > 0 && (
                            <div className="mb-1">
                                <Rating rating={reviews_score/reviews_count} reviewsCount={reviews_count} maxValue="5" />
                                &nbsp;({reviews_count})
                            </div>
                        )
                    }
                    <div className="mb-2">Prezzo: {price} &euro;</div>
                    <CardThumbnailsGroup openModal={openModal} isModalOpen={isModalOpen} />
                </div>
        
                <div className="my-2 text-justify font-italic">{description}</div>

                {
                    !isSelected && (
                            <button type="button" onClick={callbackOnSelect} className=" btn btn-primary btn-sm btn-block">
                                Aggiungi <small><i className="fas fa-shopping-cart"></i></small>
                            </button>
                    )
                }

            </div>

        </div>
    );

}

export default ServiceCard;