import { call, put, takeLatest } from "redux-saga/effects";

import {
  reviewRequest,
  reviewRequestFail,
  reviewRequestSuccess,
} from "../slices/reviews";

import Api from "../api";

function* reviewListSaga({ payload: { profileUuid } }) {
  try {
    const response = yield call(Api.reviews.list, profileUuid);
    yield put(
      reviewRequestSuccess(response.data.entities.reviews_received.items.data)
    );
  } catch (error) {
    yield put(reviewRequestFail("showProfilesSaga error", error));
  }
}

export default function* reviewSaga() {
  yield takeLatest(reviewRequest.toString(), reviewListSaga);
}
