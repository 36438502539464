import React from "react";

import { usePlacesWidget } from "react-google-autocomplete";
import AppConfig from "../../config";
import Form from "react-bootstrap/Form";

function GoogleAutoComplete({ onChangeProvince }) {
  const { ref: bootstrapRef } = usePlacesWidget({
    apiKey: AppConfig.services.google.maps_api_key,
    onPlaceSelected: (place) => {
      // Extract city name from the place object
      const province = place.address_components.find((component) =>
        component.types.includes("locality")
      );
      // Get the city name or set to an empty string if not found
      const provinceName = province ? province.long_name.toLowerCase() : "";
      onChangeProvince(provinceName);
    },
  });
  return (
    // <Form>
    //   <Form.Group controlId="formBasicEmail">
    <Form.Control ref={bootstrapRef} />
    //   </Form.Group>
    // </Form>
  );
}

export default GoogleAutoComplete;
