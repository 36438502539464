import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from "../components/Header";

// import Booking from '../pages/Booking';
import ModalHtmlRender from '../components/ModalHtmlRender';



export default function ProfileLayout() {

    const [isModalPrivacyOpen, setModalPrivacyOpen ] = useState(false);
    const [isModalTermsOpen, setModalTermsOpen ]     = useState(false);

    return (
        <div className="container bg-white">
            <Header logo_url="./logo-black.webp" />
            <Outlet />
            <div className="row">
                <div className="col-12 d-flex flex-column text-center py-2 pt-3">
                    <span>Profilo realizzato con biutify.it, la community professionale della bellezza.</span>
                    {/* <span>
                        <button type="button" className="btn btn-link" onClick={() => setModalPrivacyOpen(!isModalPrivacyOpen)}>
                            <u>Privacy policy</u>
                        </button>
                        {'-'}
                        <button type="button" className="btn btn-link" onClick={() => setModalTermsOpen(!isModalTermsOpen)}>
                            <u>Termini e condizioni</u>
                        </button>
                    </span> */}
                </div>
            </div>

            <ModalHtmlRender 
                url={'https://www.iubenda.com/api/privacy-policy/17467933/no-markup'}
                title={'Privacy Policy'}
                isModalOpen={isModalPrivacyOpen}
                toggleModal={() => setModalPrivacyOpen(!isModalPrivacyOpen)}
            />

            <ModalHtmlRender 
                url={'https://www.iubenda.com/api/termini-e-condizioni/17467933/no-markup'}
                title={'Termini e Condizioni'}
                isModalOpen={isModalTermsOpen}
                toggleModal={() => setModalTermsOpen(!isModalTermsOpen)}
            />

            {/* <MessengerCustomerChat appId={profile.facebook_appid} pageId={profile.facebook_pageid} /> */}

        </div>

    )
}