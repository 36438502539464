import { useState, useEffect } from 'react';


// da usare senza infinite scroll: problema architettura fra hooks che non possono essere annidati
export default function useFetch(url) {

    const [data, setData]       = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError]     = useState(null)
  
    useEffect(
      () => {
        if (!url) {
          setError('Url not provided.');
          return [false, error, null];
        }
  
        const fetchData = async () => {
          const res = await fetch(url)
            .then(async data => await data.json())
            .then(data => {
              setData(data)
              setLoading(false)
            })
            .catch(err => {
              setError(err.toString())
              setLoading(false)
            })
        }
  
        fetchData();
      },
      [url],
    )
  
    return [loading, error, data]
  
}