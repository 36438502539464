import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  error: null,
  status: 'idle', // "idle", "loading", "completed"
}

const SysSpecialtiesSlice = createSlice({
  name: 'sys_specialties',
  initialState,
  reducers: {
    specialtiesRequest(state) {
      state.status = 'loading'
    },
    specialtiesRequestSuccess(state, { payload }) {
      state.data = payload
      state.status = 'completed'
    },
    specialtiesRequestFail(state, { payload }) {
      state.error = payload
      state.status = 'idle'
    },
  },
})

export const {
  specialtiesRequest,
  specialtiesRequestSuccess,
  specialtiesRequestFail,
} = SysSpecialtiesSlice.actions

export default SysSpecialtiesSlice
