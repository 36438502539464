import React, { useState } from "react";
import moment from "moment";

import { getDaysOfWeek } from "./utils";

import DaysNavigator from "./DaysNavigator";
import DayButton from "./DayButton";
import SlotsList from "./SlotsList";

import "./styles/calendar.css";

// import ProfileContext from '../../scenes/Profile/context';
// const context = useContext(ProfileContext);

const Calendar = ({
  profileUuid,
  selectedDay,
  selectedBlock,
  setSelectedDay,
  onSelectBlock,
}) => {
  const [weekNumber, setWeekNumber] = useState(moment().isoWeek()); // isoweek number
  let weekStartDay = moment().isoWeek(weekNumber).startOf("isoWeek");
  let weekEndDay = moment().isoWeek(weekNumber).endOf("isoWeek");
  let daysOfWeek = getDaysOfWeek(weekStartDay, weekEndDay);

  // Quando cambio la settimana cambio anche il giorno selezionato di default
  // impostando il primo giorno della settimana
  const onChangeWeek = (weekNumber) => {
    setWeekNumber(weekNumber);
    setSelectedDay(
      moment().isoWeek(weekNumber).startOf("isoWeek").format("YYYY-MM-DD")
    );
  };

  if (daysOfWeek.length < 7) return <p>Loading...</p>;

  return (
    <section id="calendar">
      <DaysNavigator weekNumber={weekNumber} onChangeWeek={onChangeWeek}>
        {["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"].map((dayName, i) => (
          <DayButton
            key={`dayButton_${i}`}
            dayName={dayName} // lun, mar, mer, giov...
            dayNumber={daysOfWeek[i]} // 1, 2, 3, 4...
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
          />
        ))}
      </DaysNavigator>
      <SlotsList
        profileUuid={profileUuid}
        weekStartDay={weekStartDay}
        weekEndDay={weekEndDay}
        selectedDay={selectedDay}
        selectedBlock={selectedBlock}
        onSelectBlock={onSelectBlock}
      />
    </section>
  );
};

export default Calendar;
