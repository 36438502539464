import { http } from "helpers2impact";
import { slots as mockedData } from "../mocks/slots";

const config = {
  mockedData: false,
  version: "v20181007",
};

const pathSlot = (hostname) => {
  return {
    listSlots: async (profileUuid, from, to) => {
      const url = `${hostname}/public/profiles/${profileUuid}/agenda/slots?from=${from}&to=${to}`;
      return config.mockedData
        ? mockedData
        : http(config.cookieName, config.loginPath).get(url);
    },
  };
};
export default pathSlot;
