import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./home.css";


const HomeLayout = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Scopri i professionisti della bellezza nella tua citt&agrave; | biutify.it
        </title>
        <meta
          name="description"
          content={`Lista profili dei professionisti nel settore della bellezza`}
        />
        <link rel="canonical" href={`https://www.biutify.it/`} />
      </Helmet>
      <div className="default-layout">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default HomeLayout;
