import moment from 'moment';


const DaysNavigator = ({ children, weekNumber, onChangeWeek }) => {

    const _previousWeek = weekNumber => parseInt(weekNumber)-1;
    const _nextWeek = weekNumber => parseInt(weekNumber)+1;
    const validateDate = weekNumber => weekNumber > moment().week();

    return (
        <>
            <div className="mt-4 alert alert-primary text-center" role="alert">
                {`Agenda ${moment().week(weekNumber).format('MMMM YYYY')}`}
            </div>

            <div className="row justify-content-center mb-4">
                <div className="col-12 d-flex justify-content-around align-items-end calendar-col">

                    {/* previous week button */}
                    <button 
                        className={ validateDate(weekNumber) ? "btn btn-link" : "btn btn-link text-muted invisible" }
                        onClick={()=>onChangeWeek(_previousWeek(weekNumber)) }
                    >
                        <i className="fa fa-arrow-left"></i>
                    </button>

                    {/* list of days of the week - component SlotButton.js */}
                    { children }

                    {/* next week button */}
                    <button className="btn btn-link" onClick={()=>onChangeWeek(_nextWeek(weekNumber)) }>
                        <i className="fa fa-arrow-right"></i>
                    </button>

                </div>
            </div>
        </>
    );

}

export default DaysNavigator;