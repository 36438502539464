import React from 'react'
import { Spinner } from 'reactstrap'

export default function MySpinner({ type, size, color }) {
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center', // Set the height of the container to the full viewport height
        zIndex: 999999,
      }}
    >
      <Spinner className="spinner" color="secondary" />
    </div>
  )
}
