import { all } from "redux-saga/effects";

import {
  AuthSaga,
  ProfileSaga,
  ProfileDetailsSaga,
  SpecialitySaga,
  ProvinceSaga,
  ReviewSaga,
  ServiceSaga,
  SlotSaga,
  AppointmentSaga,
} from "./saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    ProfileSaga(),
    ProfileDetailsSaga(),
    SpecialitySaga(),
    ProvinceSaga(),
    ReviewSaga(),
    ServiceSaga(),
    SlotSaga(),
    AppointmentSaga(),
  ]);
}
