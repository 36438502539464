import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    firstname: "",
    last_name: "",
    phone: "",
    password: 0,
    selected_services: [],
  },
  profileSetup: {},
  error: null,
  status: "idle",
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    newRegistrationRequest(state, action) {
      state.status = "loading";
    },
    newRegistrationRequestSuccess(state, action) {
      state.data = action.payload;
      state.status = "completed";
      state.error = null;
    },
    newRegistrationRequestFail(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    newRegistrationSetupRequest(state, action) {
      state.status = "loading";
    },
    newRegistrationSetupRequestSuccess(state, { payload }) {
      state.profileSetup = payload;
      state.isAuthenticated = "true";
      state.status = "completed";
      state.error = null;
    },
  },
});

export const {
  newRegistrationRequest,
  newRegistrationRequestSuccess,
  newRegistrationRequestFail,
  newRegistrationSetupRequest,
  newRegistrationSetupRequestSuccess,
} = authSlice.actions;

export default authSlice;
