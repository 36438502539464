import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  status: "idle",
};

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    reviewRequest(state, action) {
      state.status = "loading";
    },
    reviewRequestSuccess(state, { payload }) {
      state.data = payload;
      state.status = "completed";
      state.error = null;
    },
    reviewRequestFail(state, { payload }) {
      state.error = payload;
      state.status = "error";
    },
  },
});

export const { reviewRequest, reviewRequestSuccess, reviewRequestFail } =
  reviewSlice.actions;

export default reviewSlice;
