import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlaceholder from "react-placeholder";
import { useParams } from "react-router-dom";
import moment from "moment";

import { profileDetailsRequest } from "../../store/slices/profileDetails";

import {
  MetaInfo,
  CardAppointment,
  FormConfirmAppointment,
  ModalAddressMap,
  ModalReviews,
  ModalMasonry,
  ModalConfirmAppointment,
  CardCheckoutCountdown,
  ProfileCard,
  PlaceholderProfile,
  WizardForm,
  WizardTab,
  ListServices,
  Calendar,
} from "../../components";

import AppConfig from "../../config";
//import Actions from "../../store/_actions";

// import 'antd-mobile/dist/antd-mobile.css'
import "./style.css";

const BookingPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileDetailsRequest({ slug }));
  }, [dispatch, slug]);

  const [modalOpened, setModalOpened] = useState(false); // set with the modal name

  // const [customerFullname, setCustomerFullname] = useState(null);
  // const [customerPhone, setCustomerPhone] = useState(null);
  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD")); // today is selected by default
  const [selectedBlock, setSelectedBlock] = useState(null); // booking time block
  const [selectedServices, setSelectedServices] = useState([]); // booking services
  const [customerNotes, setCustomerNotes] = useState(null);

  const { data: profile, status: profileStatus } = useSelector(state => state.ProfileDetails);

  // open when his name is setted, closed when status is false
  const openModal = (name) => {
    setModalOpened(name);
    console.log("openModal: " + name);
  };

  // Tab lista servizi
  const _onSelectService = (servicesList) => {
    setSelectedServices(servicesList);
    console.log(
      "Profile index.js - added Service: " + JSON.stringify(servicesList)
    );
  };

  // Tab calendario
  const setCalSelectedDay = (day) => {
    const checkDay =
      moment(day).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
        ? moment().format("YYYY-MM-DD")
        : day;
    setSelectedDay(checkDay);
    console.log("Profile index.js - onSelectDay: Selected day --> " + checkDay);
  };

  const onSelectBlock = (block) => {
    setSelectedBlock(block);
    console.log(
      "Profile index.js - onSelectBlock: Selected block --> " + block
    );
  };

  // tab riepilogo - debounce adopted inside the component
  const updateNotes = (notes) => {
    setCustomerNotes(notes);
    console.log("Profile index.js - updateNotes: current notes --> " + notes);
  };

  // function create a new appointment
  // const takeAppointment = (
  //   fullname,
  //   phone,
  //   day,
  //   slot_start,
  //   notes,
  //   address,
  //   referral_uuid,
  //   profile_uuid,
  //   selected_services
  // ) => {
  //   Actions.createAppointment({}, (sended) => {
  //     if (sended) {
  //       this.setState({ isAuthModalOpen: false }, () =>
  //         this.setState({ isMapModalConfirmedOpen: true })
  //       );
  //     }
  //   });
  // };

  // tempo scaduto per la compilazione del form
  // ritorno all'inizio del wizard (step 1 di 3)
  // const timeElapsed = () => {
  //   // props.history.push("./step2");
  // };

  if (profileStatus !== "completed") return "Loading..."; //<Loader />;

  return (
    <>
      <MetaInfo
        title={profile?.user?.fullname}
        description={`Profilo di ${profile?.user?.fullname} | biutify è il network della bellezza`}
        canonical={`${AppConfig.canonical}/${profile.slug}`}
      />

      <ReactPlaceholder
        ready={profileStatus === "completed"}
        customPlaceholder={
          <PlaceholderProfile pic_profile={AppConfig.ui.defaultPicProfile} />
        }
      >
        <div className="row justify-content-md-center p-3">
          <div className="col-12 col-md-6">
            <ProfileCard
              profile={profile}
              defaultPicProfile={AppConfig.ui.defaultPicProfile}
              openModalMap={() => openModal("map")}
              openModalReviews={() => openModal("reviews")}
              openModalThumbs={() => openModal("masonry")}
            />
            <div className="m-1 text-right">
              <small>
                {`Crea anche tu il tuo profilo su`}&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={AppConfig.referral.href}
                  title={AppConfig.referral.title}
                >
                  {AppConfig.referral.label}
                </a>
              </small>
            </div>
          </div>
        </div>
      </ReactPlaceholder>

      <WizardForm
        _final={() => openModal("login")}
        selectedServices={selectedServices}
        selectedBlock={selectedBlock}
      >
        <WizardTab
          title={"Servizi"}
          subtitle="Seleziona la prestazione"
          component={
            <ListServices
              profileUuid={profile.uuid}
              selectedServices={selectedServices}
              onSelectService={_onSelectService}
              setModalOpen={openModal}
              isModalOpen={modalOpened === "imagesMasonry"}
            />
          }
          actions={{
            displayOn: selectedServices.length > 0,
            subtext: "per selezionare la data dell'appuntamento",
          }}
        />
        <WizardTab
          title="Quando"
          subtitle="Seleziona un giorno e l'orario"
          component={
            <Calendar
              profileUuid={profile.uuid}
              selectedDay={selectedDay}
              selectedBlock={selectedBlock}
              setSelectedDay={setCalSelectedDay}
              onSelectBlock={onSelectBlock}
            />
          }
          actions={{
            displayOn: selectedBlock && Object.keys(selectedBlock).length > 0,
            subtext: "per verificare i dati della tua prenotazione",
          }}
        />
        <WizardTab
          title="Riepilogo"
          subtitle="Controlla i dati"
          component={
            <div className="row">
              <div className="col-12 m-0 p-0">
                <CardCheckoutCountdown />
              </div>
              <CardAppointment
                selectedServices={selectedServices}
                selectedDay={selectedDay}
                selectedBlock={selectedBlock}
                fullname={profile?.user && profile?.user?.fullname}
                address={profile.user?.position?.address || ""}
                updateNotes={updateNotes}
                openModal={openModal}
              />
              <br />
              <FormConfirmAppointment
                day={moment(selectedDay).format("YYYY-MM-DD")}
                selectedBlock={selectedBlock}
                notes={customerNotes}
                address={profile.user.position.address}
                profile_uuid={profile.uuid}
                selectedServices={selectedServices.map((service) => ({
                  service_uuid: service.uuid,
                }))}
              />
            </div>
          }
          actions={{
            displayOn: selectedServices.length > 0 && selectedBlock !== null,
            subtext: "Procedi per confermare la tua prenotazione in agenda",
          }}
        />
      </WizardForm>

      <ModalAddressMap
        isModalOpen={modalOpened === "map"}
        openModal={openModal}
        title={`Indirizzo di ${profile.user && profile.user.fullname}`}
        coordinates={profile.user && profile.user.position?.point?.coordinates}
        googleMapKey={AppConfig.services.google.maps_api_key}
        zoom={15}
      />

      <ModalReviews
        isModalOpen={modalOpened === "reviews"}
        openModal={openModal}
        title={`Recensioni di ${profile.user && profile.user.fullname}`}
        profileUuid={profile.uuid}
      />

      <ModalMasonry
        isModalOpen={modalOpened === "masonry"}
        openModal={openModal}
        title={profile.user && `Portfolio di ${profile.user.fullname}`}
        images={profile.portfolios}
      />

      {/* <ModalLogin
        isModalOpen={modalOpened==="login"}
        openModal={openModal}
        title={'Accedi per confermare i tuoi dati'}
        ss={selectedServices} // booking services
        sd={selectedDay}      // today is selected by default
        sb={selectedBlock}    // booking time block
        cn={customerNotes}
        cf={customerFullname}
        cp={customerPhone}
      /> */}

      {/* <ModalConfirmAppointment
        isModalOpen={modalOpened==="confirm_booking"}
        setModalOpen={setModalOpened}
        title={`Prenota il tuo appuntamento`}
        profileSlug={profile.user && profile.referral.slug}
        onSubmit={() => window.location.reload()}
      /> */}
    </>
  );
};

export default BookingPage;
