import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Masonry from 'react-masonry-css'
import Dummy from 'dummyjs'

import './style.css'

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}

// le modals vengono rendirizzate anche quando non aperte
// modificarle nella pagina inserendo la condizione al di fuori
// ed agganciandola ad una portal

const ModalMasonry = ({ isModalOpen, openModal, title, images }) => {
  console.log('ModalMasonry - isModalOpen: ' + isModalOpen)
  console.log('ModalMasonry - images: ', images)

  return (
    <Modal
      size="xl"
      backdrop={true}
      centered
      isOpen={isModalOpen}
      toggle={() => openModal(null)}
    >
      <ModalHeader toggle={() => openModal(null)}>
        {title}
        <button className="modal-close-btn" onClick={() => openModal(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="overflow-auto render-modal-body">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {images.map((image, i) => (
            <div key={`masonry_${image.uuid}_${i}`}>
              <strong>{image.label}</strong>
              <div className="my-3">
                <img
                  src={image.url}
                  alt={image.label}
                  style={{ width: '100%' }}
                />
              </div>
              <div>{image.service.default_service.label}</div>
            </div>
          ))}
        </Masonry>
      </ModalBody>
    </Modal>
  )
}

ModalMasonry.defaultProps = {
  title: 'title',
  isModalOpen: false,
}

export default ModalMasonry
