import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import HomeLayout from "./layouts/home";
import ProfileLayout from "./layouts/profile";
import NotFound from "./pages/NotFound";

import MasonryProfilesPage from "./pages/MasonryProfiles";
import BookingPage from "./pages/Booking";
import VoucherPage from "./pages/Voucher";
import { store } from "./store";
import Registration from "./pages/Auth/login";
import "bootstrap/dist/css/bootstrap.css";
//import Registration from './pages/Auth/registration'

const App = () => {
  // load progress bar on every http request by custom axios instance
  const isAuthenticated = true;

  return (
    <Provider store={store}>
      <BrowserRouter>
        {isAuthenticated ? <AllRoutes /> : <AllRoutes />}
      </BrowserRouter>
    </Provider>
  );
};
const UnauthenticatedRoutes = () => (
  <Routes>
    {/* <Route path="/" element={<HomeLayout />}> */}
    <Route path="/" element={<Registration />} />
    <Route path="/registration" element={<Registration />} />
    {/* <Route path="/login" element={<Login />} /> */}
    {/* <Navigate to="/registration" /> */}
    {/* </Route> */}
  </Routes>
)
const AllRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route path="/" element={<MasonryProfilesPage />} />
      </Route>
      <Route path="/professionisti/registrazione" element={<Registration />} />
      <Route path="/:slug" element={<ProfileLayout />}>
        <Route path="/:slug" element={<BookingPage />} />
        <Route path="/:slug/voucher" element={<VoucherPage />} />
      </Route>
      <Route path="*" component={NotFound} />
    </Routes>
  </>
)

export default App;
