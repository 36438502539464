import CopyToClipboard from 'react-copy-to-clipboard';
import { PayPalButton } from "react-paypal-button-v2";

import ServiceCard from '../Services/Card';

// https://www.npmjs.com/package/voucher-code-generator
// var voucher_codes = require('voucher-code-generator');


const copyButton = (value) => {
    <CopyToClipboard text={value}>
      <button className="copy" />
    </CopyToClipboard>
}

      {/* <CopyToClipboard text={'copiami tutta'}>
        <button className="btn btn-primary">Copia</button>
      </CopyToClipboard> */}


const Voucher = ({ data:{ uuid, cover_img, title, description, valid_until, selectedServices, bonusServices, expires_after}}) => {

  const totalCost = selectedServices.reduce((total, serviceInfo) => total+=(serviceInfo.service.price*serviceInfo.quantity),0);
  const discountPrice = totalCost-parseInt(totalCost*30/100);

  return (
    <div className="row my-5">
      <div className="col-12 d-flex flex-column py-2 pt-3">
        
        <div className="card">
          <img className="card-img-top" src={cover_img.src} alt={cover_img.alt} />
          <div className="card-header"><h2 className="text-center">{title}</h2></div>
          <div className="card-body">
            {/* <h6 className="card-subtitle mb-2 text-muted text-right">Valida fino al {valid_until}</h6> */}
            <p className="card-text">{description}</p>
            <>
              {
                selectedServices.map( serviceInfo => 
                  <div className="text-center my-4">
                    <h3>{serviceInfo.quantity} x {serviceInfo.service.default_service.label}</h3>
                    <span>Prezzo intero: {serviceInfo.service.price} €, totale {parseInt(serviceInfo.quantity*serviceInfo.service.price)} €</span>
                  </div>
                )
              }
            </>
            <div className="alert alert-success text-center mt-5" role="alert">
              <p className="font-weight-bold">Promozione valida fino al {valid_until}</p>
              <hr />
              <h4>{discountPrice}€ invece di {totalCost}€</h4>
            </div>
            <PayPalButton
              amount={discountPrice}
              currency={'EUR'}
              options={
                {
                  clientId:"AU-XkQTJcLNKPV5GHKmySD3LhPSUPOGws-05YwYch6lSFHRkGAJyPDAafnzoXY7NOblyLG1dbXdJACA_",
                  debug:"true"
                }
              }
              locale="it_IT"
              // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
              onSuccess={(details, data) => {
                alert("Transaction completed by " + details.payer.name.given_name);
                return fetch("/payment-complete", {
                  method: "post",
                  body: JSON.stringify({
                    orderID: data.orderID
                  })
                });
              }}
            />
          </div>
        </div>

      </div>
    </div>
  )

}

export default Voucher;