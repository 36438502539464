import { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { getBlocks, getAvailableBlocks } from "./utils";
import SlotButton from "./SlotButton";
import { slotRequest } from "../../store/slices/slots";
import MySpinner from "../Spinner";

const SlotsList = ({
  profileUuid,
  weekStartDay,
  weekEndDay,
  selectedDay,
  selectedBlock,
  onSelectBlock,
}) => {
  const dispatch = useDispatch();

  const from =
    moment().diff(weekStartDay) > 0
      ? moment().format("YYYY-MM-DD")
      : weekStartDay.format("YYYY-MM-DD");

  const { data: slots, status: slotStatus } = useSelector(
    (state) => state.Slots
  );

  useEffect(() => {
    dispatch(
      slotRequest({
        profileUuid,
        from,
        to: weekEndDay.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, profileUuid, from, weekEndDay]);

  const allBlocks = getBlocks("07:00", 64, 15);
  let availableBlocks = [];

  if (slotStatus === "error") return <p>Error slots list</p>;
  if (slotStatus === "loading") return <MySpinner />;

  if (slots && slots[selectedDay]) {
    availableBlocks = getAvailableBlocks(allBlocks, slots[selectedDay]);
  }

  return (
    <div className="row">
      <div className="col-12 text-center">
        <strong>
          {
            `Giorno ${moment(selectedDay).format("D MMMM")}` // from string to moment formatted string
          }
        </strong>
        <div className="row">
          {availableBlocks?.length > 0 ? (
            availableBlocks?.map((block, i) => (
              <SlotButton
                key={`slotButton_${i}`}
                label={block?.label}
                isSelected={selectedBlock !== null && selectedBlock.index === i}
                onSelectBlock={() =>
                  onSelectBlock({ index: i, label: block?.label })
                }
                disable={block?.disable}
              />
            ))
          ) : (
            <div className="col-12 text-center">
              <div className="mt-4 alert alert-danger text-center" role="alert">
                <strong>Agenda piena!</strong>
                <br />
                <small>Seleziona un altro giorno</small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlotsList;
