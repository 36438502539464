import { call, put, takeLatest } from "redux-saga/effects";

import {
  profilesRequest,
  profilesRequestFail,
  profilesRequestSuccess,
} from "../slices/profiles";

import Api from "../api";

function* profilesSaga({ payload: { specialty, province } }) {
  try {
    const response = yield call(Api.profile.list, specialty, province);
    yield put(
      profilesRequestSuccess(response.data.entities.profiles.items.data)
    );
  } catch (error) {
    yield put(profilesRequestFail("showProfilesSaga error", error));
  }
}

export default function* profileSaga() {
  yield takeLatest(profilesRequest.toString(), profilesSaga);
}
