// EP: /:slug/slots

export const slots = {
  "meta": {
    "href": "/nadiarossi90/slots?from=2019-11-11&to=2019-11-17",
    "title": "Profile's availabe slots",
    "description": "bla bla ... description",
    "keywords": "bla bla bla ... keywords",
    "author": "biuti engineering team - engineering@biuti.it"
  },
  "results": {
    "slots": {
      "2019-11-11": "1111111111111111111111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000011111",
      "2019-11-12": "1111111111111100000000000000000000000000000000000000000000000000000000000000000000000000011111110000000000001111111000000001111111111111111111111111111111111111111111111111111111111111111111",
      "2019-11-13": "1111111111111111111111111111111111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000001111111111111111111111111111111111111111111111111111111111111111",
      "2019-11-14": "1111111111111111111111111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000000000000000000000000000011111111111111111111111111111111111111111111111111111",
      "2019-11-15": "1111111111111111000000000000000000000000000000000000000000000000000000000000000000000000000000000000001111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
      "2019-11-16": "1111111111111111111111111111111111111110000000000000000000000000000000000000000000000000011111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
      "2019-11-17": "1111111111111111100000000000000000000000000000000000000000000000000000000000000000000000000000000011111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111"
    }
  }
}