import { call, put, takeLatest } from "redux-saga/effects";

import {
  profileDetailsRequest,
  profileDetailsRequestFail,
  profileDetailsRequestSuccess,
} from "../slices/profileDetails";

import Api from "../api";

function* profileDetailsSaga({ payload: { slug } }) {
  try {
    const response = yield call(Api.profile.show, slug);
    yield put(
      profileDetailsRequestSuccess(response.data.entities.profile.item)
    );
  } catch (error) {
    yield put(profileDetailsRequestFail("profileDetailsSaga error", error));
  }
}

export default function* profileSaga() {
  yield takeLatest(profileDetailsRequest.toString(), profileDetailsSaga);
}
