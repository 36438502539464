import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  status: "idle",
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    serviceRequest(state, action) {
      state.status = "loading";
    },
    serviceRequestSuccess(state, { payload }) {
      state.data = payload;
      state.status = "completed";
      state.error = null;
    },
    serviceRequestFail(state, { payload }) {
      state.error = payload;
      state.status = "error";
    },
  },
});

export const { serviceRequest, serviceRequestSuccess, serviceRequestFail } =
  serviceSlice.actions;

export default serviceSlice;
