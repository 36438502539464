export const profile = {
  "meta": {
    "href": "/profile-slug-ig",
    "title": "User's profiles",
    "description": "bla bla ... description",
    "keywords": "bla bla bla ... keywords",
    "author": "biuti engineering team - engineering@biuti.it"
  },
  "results": {
    "profile": {
      "uuid": "7c915add-674f-49dc-b797-0621995ab17b",
      "is_public": true,
      "certified_at": "2019-10-14T07:34:16.144Z",
      "appointments_count": 10,
      "reviews_made_count": 6,
      "reviews_made_score": 14,
      "reviews_received_count": 6,
      "reviews_received_score": 16,
      "credit": 20,
      "trust": 5,
      "celebrity": 92,
      "points": 0,
      "ranking": 0,
      "created_at": "2019-03-15T23:30:48.282Z",
      "updated_at": "2019-10-14T16:15:28.098Z",
      "type": {
        "id": 2,
        "code": "artist",
        "label": "Artist"
      },
      "specialties": [
        {
          "uuid": "baba86cb-a6bc-4bde-9418-f7bcee146b00",
          "name": "Massaggiatore",
          "slug": "massaggiatori",
          "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          "info": {
            "verified_at": null
          }
        },
        {
          "uuid": "b62f09c5-e36e-407e-8f24-3e681f91b4d7",
          "name": "Estetista",
          "slug": "estetiste",
          "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          "info": {
            "verified_at": null
          }
        }
      ],
      "referral": {
        "uuid": "b68ab4d4-4f64-4439-a279-31c00fa18edb",
        "slug": "profile-slug-ig",
        "utm_source": "Instagram",
        "utm_medium": "socials",
        "utm_campaign": "profile-bio",
        "utm_content": null,
        "utm_term": null,
        "notes": "Copia questo link tracciante sulla tua bio di Instagram o come commento alle tue foto"
      },
      "user": {
        "fullname": "Firstnametest Lastnametest",
        "uuid": "008d55cd-4edb-47a2-80df-92baeb287921",
        "firstname": "Firstnametest",
        "lastname": "Lastnametest",
        "slug": "slug-di-test",
        "birthday_at": "1982-12-23",
        "phone": "3331234567",
        "email": "test@biuti.it",
        "fiscal_code": null,
        "vat_code": null,
        "iban_code": null,
        "home_address": null,
        "position_uuid": "f2ce3b0e-a922-4460-8838-5758eac998a8",
        "contacts_count": 60,
        "contacts_invited": 0,
        "last_login_at": "2019-10-13T18:59:15.142Z",
        "created_at": "2019-10-14T16:15:22.321Z",
        "role": {
          "uuid": "69654e0f-cc86-4799-a42a-f23f5cffebc9",
          "label": "User",
          "description": null,
          "sort": 2
        },
        "status": {
          "id": 2,
          "label": "registered",
          "description": null,
          "sort": 1
        },
        "pic_profile": null,
        "position": {
          "uuid": "f2ce3b0e-a922-4460-8838-5758eac998a8",
          "point": {
            "type": "Point",
            "coordinates": [
              37.517158,
              15.091841
            ]
          },
          "address": "Via Vincenzo Giuffrida, 18, 95128 Catania CT, Italy",
          "city": "Catania",
          "created_at": "2019-10-14T16:15:24.823Z"
        }
      }
    }
  }
}