import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactPlaceholder from "react-placeholder";
import { useDispatch, useSelector } from "react-redux";

import ReviewCard from "../CardReview";
import { reviewRequest } from "../../store/slices/reviews";

import "./style.css";

// le modals vengono rendirizzate anche quando non aperte
// modificarle nella pagina inserendo la condizione al di fuori
// ed agganciandola ad una portal

const ModalReviews = ({ isModalOpen, openModal, title, profileUuid }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reviewRequest({ profileUuid }));
  }, [dispatch, profileUuid]);

  const { data: reviews, status: reviewStatus } = useSelector(
    (state) => state.Reviews
  );
  if (reviewStatus !== "completed")
    return (
      <ReactPlaceholder
        type="textRow"
        color="#E0E0E0"
        ready={reviewStatus === "completed"}
        showLoadingAnimation
        style={{ width: "50%" }}
      >
        <p>Caricamento...</p>
      </ReactPlaceholder>
    );

  return (
    <Modal
      size="lg"
      backdrop={true}
      centered
      isOpen={isModalOpen}
      toggle={() => openModal(null)}
    >
      <ModalHeader toggle={() => openModal(null)}>
        {title}
        <button className="modal-close-btn" onClick={() => openModal(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="overflow-auto">
        {reviews && reviews.length > 0 && reviews.map((review, i) => (
            <ReviewCard
              key={i}
              review={review}
              hideSeparator={i === reviews.length - 1}
            />
        ))}
      </ModalBody>
    </Modal>
  );
};

ModalReviews.defaultProps = {
  title: "title",
  isModalOpen: false,
};

export default ModalReviews;
