// https://github.com/trekhleb/use-position

import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeroSection from "../../components/Profile/HeroSection";
import ProfessionalsSection from "../../components/Profile/ProfessionalsSection";
import MySpinner from "../../components/Spinner";
import { specialtiesRequest } from "../../store/slices/sys_specialties";
import { provincesRequest } from "../../store/slices/sys_provinces";
import { profilesRequest } from "../../store/slices/profiles";


const MasonryProfiles = () => {
  const dispatch = useDispatch();

  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const { data: specialties, status: specialtiesStatus } = useSelector(state => state.Speciality);
  const { data: provinces } = useSelector((state) => state.Province);
  const { data: profiles } = useSelector((state) => state.Profiles);

  // console.log("profiles:", profiles);

  const dropdownSpecialties = useMemo(() => {
    return (
      specialties?.map((item) => ({
        value: item?.slug,
        label: item?.name,
      })) || []
    );
  }, [specialties]);

  const handleSpecialtyChange = (specialty) => {
    setSelectedSpecialty(specialty);
  };
  const handleProvinceChange = (province) => {
    console.log("Province:", province);
    setSelectedProvince(province); // Update the city state
  };

  useEffect(() => {
    dispatch(
      profilesRequest({
        specialty: selectedSpecialty,
        province: selectedProvince,
      })
    );
  }, [dispatch, selectedSpecialty, selectedProvince]);

  useEffect(() => {
    dispatch(specialtiesRequest());
    dispatch(provincesRequest());
  }, [dispatch]);

  if (specialtiesStatus !== "completed") return <MySpinner />;

  return (
    <>
      <HeroSection
        provinces={provinces}
        specialties={dropdownSpecialties}
        onSelectSpecialty={handleSpecialtyChange}
        onSelectProvince={handleProvinceChange}
      />
      <ProfessionalsSection profiles={profiles} />
    </>
  );
};

export default MasonryProfiles;