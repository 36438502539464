import { FaUserShield, FaMapMarkerAlt } from "react-icons/fa";

import CardThumbnailsGroup from "../CardThumbnailsGroup";
import Rating from "../Rating";

const ProfileCards = ({
  defaultPicProfile,
  profile = {},
  openModalThumbs,
  openModalMap,
  openModalReviews,
}) => {
  const picProfile =
    profile?.user && profile?.user?.pic_profile
      ? profile?.user?.pic_profile?.url
      : defaultPicProfile;
  return (
    <>
      {Object.keys(profile).length > 0 && (
        <div className="profile-card">
          <div className="avatar-wrap">
            <img loading="lazy" src={picProfile} alt="avatar" />
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <h3 className="name">
              {profile?.certified_at && <FaUserShield className="icon" />}
              <span> {profile?.user && profile?.user?.fullname}</span>
            </h3>

            {profile?.reviews_received_count > 0 && (
              <div className="d-flex align-items-center gap-2 mb-3">
                <Rating
                  rating={Math.round(
                    profile.reviews_received_score /
                      profile.reviews_received_count
                  )}
                  maxValue={5}
                />
                {/* <a
                  href={"#recensioni"}
                  className="link"
                  onClick={openModalReviews}
                > */}
                  ({profile.reviews_received_count})
                {/* </a> */}
              </div>
            )}
            <div className="skills d-flex flex-wrap gap-2 justify-content-center">
              {profile?.specialties?.length > 0 &&
                profile?.specialties?.map((speciality) => (
                  <span
                    className="px-3 py-1 rounded theme-bg text-white"
                    key={speciality.slug}
                  >
                    {speciality.name}
                  </span>
                ))}
            </div>
            {profile?.user && profile?.user?.position?.city && (
              <div className="d-flex align-items-center gap-2 my-3">
                <FaMapMarkerAlt className="text-danger" />
                {/* <a href={"#indirizzo"} onClick={openModalMap}> */}
                  {`${profile?.user && profile?.user?.position?.city}.`}
                {/* </a> */}
              </div>
            )}
            <div className="services">
              <CardThumbnailsGroup
                thumbnails={profile?.portfolios}
                openModal={openModalThumbs}
              />
            </div>
            <div className="actions">
              <a
                href={`/${profile?.slug}`}
                className={`${
                  profile?.completed ? "" : "outline"
                } theme-btn btn-sm btn-block text-uppercase my-4`}
                role="button"
                aria-disabled="true"
              >
                Vai al suo Profilo <i className="fas fa-external-link-alt fa-sm"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileCards;
