const PlaceholderServiceCard = props => (
    <div className="card p-2 mt-3 clearfix d-flex flex-row">
        <img className="img image pull-left" height="100" width="100" src={props.pic_service} alt="pic-service" />
        <div className="pull-right w-100 pl-3">                        
            <div className="placeholderText" />
            <div className="placeholderText" />
            <div className="placeholderText" />
            <div className="placeholderText" />
        </div>
    </div>
);

const PlaceholderServicesList = props => Array.from({length: props.repeat}, (_, i) => 
    <PlaceholderServiceCard
        key={i}
        pic_service={props.pic_service}
    />
);

export default PlaceholderServicesList;