import ModalVideoTribute from "./ModalVideoTribute";
import { useState } from "react";


const Header = ({logo_url='./logo-white.png'}) => {
    const [isModalVideoOpen, setModalVideoOpen] = useState(false);
    const openModalVideo = () => {
        console.log("openModalVideo");
        setModalVideoOpen(true);
    }
    const closeModalVideo = () => {
        setModalVideoOpen(false);
    }
    return (
        <>
            {isModalVideoOpen && (
                <ModalVideoTribute
                    showModal={isModalVideoOpen}
                    toggleModal={closeModalVideo}
                />
            )}
            <header className="primary-header">
                <nav className="navbar primary-nav navbar-expand-md">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img className="brand-logo" src={logo_url} alt="biutify logo" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a
                                        href={"#video-promo"}
                                        title="A Voi che ogni giorno rendete più bella la vita di tutti noi"
                                        className="nav-link login-btn text-uppercase theme-btn rounded outline"
                                        onClick={openModalVideo}
                                    >
                                        Video promo
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link professionals-btn text-uppercase theme-btn rounded outline" href="/wp">
                                        <i className="fa-user-alt icon"></i>
                                        <span className="mr-1">Area Professionisti</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>        
    );
};

export default Header;
