import React, { useState } from 'react'
import Actions from './Actions'

import { Steps } from 'antd-mobile'

import './style.css'
// https://bbbootstrap.com/snippets/bootstrap-progress-bar-steps-38773259

const { Step } = Steps

const WizardForm = ({ _final, selectedServices, selectedBlock, children }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const _next = () => setCurrentStep(currentStep + 1)
  const _prev = () => setCurrentStep(currentStep - 1)

  const childArray = React.Children.toArray(children)

  return (
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-6">
        <div className="my-4">
          <Steps
            type="navigation"
            size="small"
            direction="horizontal"
            current={currentStep}
            className="site-navigation-steps"
            style={{
              '--line-to-next-color': '#c3b092',
            }}
          >
            {Object.keys(childArray).map((i) => (
              <Step
                key={children[i].props.title}
                title={children[i].props.title}
              />
            ))}
          </Steps>
        </div>

        <div className="steps-content bg-white">
          {children[currentStep].props.component}
        </div>
        {/* {selectedServices && selectedServices.length > 0 && ( */}
        <Actions
          currentStep={currentStep}
          display={children[currentStep].props.actions.displayOn}
          subtext={children[currentStep].props.actions.subtext}
          stepsCount={Object.keys(children).length}
          _next={_next}
          _prev={_prev}
          _final={_final}
          selectedServices={selectedServices}
          selectedBlock={selectedBlock}
        />
        {/* )} */}
      </div>
    </div>
  )
}

export default WizardForm
