import React, { useState } from 'react'

import { Steps } from 'antd-mobile'

import './style.css'

const { Step } = Steps

const WizardForm = ({ _final, children, _next, currentStep }) => {
  const childArray = React.Children.toArray(children)

  return (
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-6 border">
        <div className="text-align-center">
          <img
            src="/logo.webp"
            alt="Logo BIUTIFY"
            className="my-0 mr-md-auto"
            style={{ maxWidth: '150px', maxHeight: '50px' }}
          />
        </div>
        <div className="my-4">
          <Steps
            type="navigation"
            size="small"
            direction="horizontal"
            current={currentStep}
            className="site-navigation-steps"
            style={{
              '--line-to-next-color': '#c3b092',
            }}
          >
            {Object.keys(childArray).map((i) => (
              <Step
                key={children[i].props.title}
                title={children[i].props.title}
              />
            ))}
          </Steps>
        </div>

        <div className="steps-content">
          {children[currentStep].props.component}
        </div>
      </div>
    </div>
  )
}

export default WizardForm
