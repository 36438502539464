import { http } from "helpers2impact";
import config from "../../config";

const authApi = (hostname) => {
  return {
    registration: async (formData) => {
      const url = `${hostname}/registration`;
      return http(config.cookieName, config.loginPath).post(url, formData);
    },
    profileSetup: async (formData, user_uuid) => {
      const url = `${hostname}/registration/${user_uuid}/profile-setup`;
      return http(config.cookieName, config.loginPath).post(url, formData);
    },
  };
};

export default authApi;
