export const fetchDecorator = (url, options, timeout=3000) => {
    return Promise.race([ 
        fetch(url, options)
            .then(res => {
                if(res.ok) return res;
                throw new Error(`${res.status}`);
            }),
        new Promise((_, reject) => {
            let startTimer = new Date();
            setTimeout(() => {
                let endTimer = new Date();
                reject(new Error('timeout ' + (endTimer - startTimer)))
            }, timeout)
        })
    ])
}

export const customFetch = (urlOrMockedData, options, timeout=3000) => {
    if(typeof urlOrMockedData === 'string') {
        return fetchDecorator(urlOrMockedData,options, timeout).then(res => {
            if(res.status === 200) return res.json();
            else throw new Error('errore downlaod');
        })
    }
    return Promise.resolve(urlOrMockedData);
}