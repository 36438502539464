import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
//import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import rootSaga from './sagas'

const isDev = process.env.NODE_ENV !== 'production'

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['Auth', 'CurrentUser'],
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  //...(isDev ? [createImmutableStateInvariantMiddleware()] : []),
  sagaMiddleware,
]
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: isDev,
})

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)
export { store, persistor }
