import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Rating from '../Rating';

import './style.css';

import 'moment/locale/it';
moment.locale('it');

export default function ReviewCard({ review: {uuid, author, rating, was_punctual, was_clean, was_kind, appointment, notes, created_at}, hideSeparator }) {

    return (

        <div className="row clearfix mb-3" key={`review-${uuid}`}>
            
            <div className="col-3 border-right">
                <h6>{author.user.fullname}</h6>
                <small className="customer-review-count">{`${author.reviews_made_count} recensioni`}</small>
            </div>

            <div className="col-8">
                <Rating rating={rating} maxValue={5} />
                <span className="pl-2 review-date">
                    {moment(created_at).format('D MMMM YYYY')}
                </span>
                <div className="d-flex flex-row my-2 review-serviceslist-container">
                    { 
                        appointment.services.map(service => 
                            <span key={service.uuid} className="service-tag">
                                {service.default_service.label}
                            </span>
                        )
                    }
                </div>
                <p className="review-text py-2">{notes}</p>
            </div>
        </div>

    )

}