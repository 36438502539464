import React from 'react'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { newRegistrationRequest } from '../../../store/slices/auth'

const StepOne = ({ _next }) => {
  const dispatch = useDispatch()
  const onFinish = (values) => {
    console.log('Success:', values)
    dispatch(
      newRegistrationRequest({
        formData: { ...values },
        _next: _next,
      }),
    )
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <Form
      name="basic"
      style={{
        maxWidth: 500,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="First name"
            name="first_name"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}
            // style={{ width: '100vh' }}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Last name"
            name="last_name"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input your phone!',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button
              style={{ backgroundColor: '#c3b092' }}
              block
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
export default StepOne
