import React from 'react'
import classNames from 'classnames'

const SlotButton = ({ label, isSelected, onSelectBlock, disable }) => {
  const buttonClass = classNames({
    'justify-content-center border': true,
    'btn btn-sm btn-block': true,
    'btn-light': !isSelected,
    'btn-primary': isSelected,
  })
  const buttonStyle = !disable ? { cursor: 'not-allowed' } : {}
  return (
    <div className="col-3 my-2">
      <button
        disabled={!disable}
        type="button"
        className={buttonClass}
        onClick={onSelectBlock}
        style={buttonStyle}
      >
        {label}
      </button>
    </div>
  )
}

export default SlotButton
