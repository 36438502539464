import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import VideoPlayer from '../VideoPlayer'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const ModalVideoTribute = ({showModal, toggleModal}) => {
  // const [neverShowAgain, setNeverShowAgain] = useState(
  //   Cookies.get('never_show_welcome_video_new') === 'true',
  // )
  // const neverShowAgainHandler = () => {
  //   Cookies.set('never_show_welcome_video_new', 'true')
  //   setShowModal(false)
  // }
  // useEffect(() => {
  //   if (!neverShowAgain) {
  //     setShowModal(true)
  //   }
  // }, [neverShowAgain])

  return (
    <Modal
      size="xl"
      style={{ maxWidth: '900px', width: '100%' }}
      backdrop={true}
      centered
      isOpen={showModal}
      toggle={() => toggleModal(false)}
    >
      <ModalHeader toggle={() => toggleModal(false)}>
        A Voi che ogni giorno rendete il mondo un posto più bello.
        <button className="modal-close-btn" onClick={() => toggleModal(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="overflow-auto render-modal-body">
        <VideoPlayer
          playsInline
          poster="https://res.cloudinary.com/rebelcodes/image/upload/ar_16:9,c_crop,e_sharpen,f_webp/v1700717994/poster_video_film_iwqps2.png"
          src="https://www.biutify.it/film.mov"
          onVideoStart={() => {}}
          // onVideoEnd={neverShowAgainHandler}
        />
        {/* <label
          htmlFor="welcome-video"
          className="-mb-4 mt-4 flex items-center justify-center"
        >
          <input
            id="welcome-video"
            name="welcome-video"
            type="checkbox"
            checked={neverShowAgain}
            onChange={(e) => setNeverShowAgain(e.currentTarget.checked)}
            className="mr-1 rounded"
          />
          Non mostrare pi&ugrave;
        </label> */}
      </ModalBody>
    </Modal>
  )
}

export default ModalVideoTribute
