import Countdown from 'react-countdown';

const label = ({ hours, minutes, seconds, completed }) => {
    return minutes === 0
    ?
        (
            <div className="alert alert-danger" role="alert">
                Attenzione: hai solo {seconds} secondi per completare la tua prenotazione!
            </div>
        )
    :
        (
            <div className="alert alert-primary" role="alert">
                Hai {minutes} minuti e {seconds} secondi per completare la tua prenotazione.
            </div>
        )
};

const CardCheckoutCountdown = () =>
    <Countdown date={Date.now() + 8*60*1000} renderer={label}>
        <p>Fine countdown</p>
    </Countdown>


export default CardCheckoutCountdown;